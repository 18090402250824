export const BOARDING = "/boarding";
export const LOGIN = "/login";
export const LIST_APPS = '/';
export const CREATE_APP = "/create-app";
export const BASE_HOME = '/home/';
export const HOME = '/home/:id';
export const DEVICES = '/backend/devices';
export const LIST_DEVICES = '/backend/list-devices';
export const RULES = '/backend/rules';
export const LIST_RULES = '/backend/list-rules';
export const SESSIONS = '/backend/sessions';
export const LIST_SESSIONS = '/backend/list-sessions';
export const USERS = '/backend/users';
export const LIST_USERS = '/backend/list-users';
export const OBJECTS = '/backend/objects';
export const LIST_OBJECTS = '/backend/list-objects';
export const MESSAGING = '/engage/messaging';
export const MESSAGING_CONFIG = '/engage/messaging-config';
export const NOTIFICATION = '/engage/notifications';
export const PUSH_NOTIFICATION = '/engage/push-notifications';
export const PUSH_MESSAGES = '/engage/push-messages';
export const CHAT_BOT = '/engage/chat-bot';
export const SMS = '/engage/sms';
export const EMAIL = '/engage/email';
export const SURVEY = '/engage/survey';
export const CAMPAIGN = '/engage/campaign';
export const CHAT_BOT_CONFIG = '/engage/chat-bot-config';
export const COLORS = '/setup/colors';
export const LIST_COLORS = '/setup/list-colors';
export const IMAGES = '/setup/images';
export const LIST_IMAGES = '/setup/list-images';
export const LABELS = '/setup/labels';
export const LIST_LABELS = '/setup/list-labels';
export const SETUP_MESSAGES = '/setup/messages';
export const SETUP_MESSAGES_LIST = '/setup/list-messages';
export const ANALYTICS = '/analytics/analytics';
export const LIST_ANALYTICS = '/analytics/list-analytics';
export const LOGS = '/analytics/logs';
export const LIST_LOGS = '/analytics/list-logs';
export const EVENTS = '/analytics/events';
export const LIST_EVENTS = '/analytics/list-events';
export const JOBS = '/integrations/jobs';
export const LIST_JOBS = '/integrations/list-jobs';
export const HOOKS = '/integrations/hooks';
export const LIST_HOOKS = '/integrations/list-hooks';
export const LIST_TRIGGERS = '/integrations/list-triggers';
export const PROFILE = "/profile";
export const ORDER = "/commerce/order";
export const CUSTOMER = "/commerce/customer";
export const CATALOG = "/commerce/catalog";
export const LIST_CATALOGS = "/commerce/list-catalogs";
export const LIST_ORDERS = "/commerce/list-orders";
export const CONFIGURE_APP = "/config-app";
export const GENERAL_SETTING = "/config-app/general-settings";
export const APP_DESIGN = "/config-app/design";
export const APP_BACKEND = "/config-app/backend";
export const APP_ANALYTICS = "/config-app/analytics";
export const RELEASE_APP = "/config-app/release";
export const PROJECTS = "/real-estate/projects";
export const PROJECT_STAGES = "/real-estate/project-stages";
export const PROJECT_UNITS = "/real-estate/units";
export const UNIT_OPTIONS = "/real-estate/unit-options";
export const UNIT_FEATURES = "/real-estate/unit-features";
export const UNIT_GALLERY = "/real-estate/unit-gallery";
export const PAYMENT_PLANS = "/real-estate/payment-plans";
export const SERVICE_REQUESTS = "/real-estate/service-requests"
export const POSTS = "/blog/posts";
export const POST_BLOCKS = "/blog/blocks";
export const BLOCK_SPANS = "/blog/block-spans";
export const BLOCK_ASSETS = "/blog/block-assets";
export const BLOCK_MARKDEFS = "/blog/block-markdefs";
export const POST_MAIN_IMAGE = "/blog/post-main-image";
export const POST_CATEGORIES = "/blog/post-categories"
export const POST_SLUGS = "/blog/post-slugs"
export const POST_AUTHORS = "/blog/post-authors"
export const POST_SITESETTINGS = "/blog/post-sitesettings"
export const POST_DETAILS = "/blog/posts/:id";
export const LEADS = "/leads";

export const INTERNAL_LINKS = [
    LIST_USERS,
    PUSH_NOTIFICATION,
    PUSH_MESSAGES,
    LIST_OBJECTS,
    LIST_DEVICES,
    LIST_RULES,
    LIST_SESSIONS,
    LIST_COLORS,
    LIST_IMAGES,
    SMS,
    CHAT_BOT_CONFIG,
    MESSAGING_CONFIG,
    LIST_LABELS,
    SETUP_MESSAGES_LIST,
    LIST_ANALYTICS,
    LIST_LOGS,
    LIST_EVENTS,
    LIST_JOBS,
    LIST_HOOKS,
    LIST_TRIGGERS,
    LIST_CATALOGS,
    LIST_ORDERS
];
