import React from "react";
import { Dropdown, Menu, Button } from "antd";
import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from './RealEstateItem';
import useUpdateClass from '../../hooks/update_class';
import { CaretDownFilled } from "@ant-design/icons";
import useGetAllUsers from "../../hooks/get_all_users";
import useGetAllCatalogs from "../../hooks/get_all_catalogs";
import useGetAllBranches from "../../hooks/get_all_branches";
import moment from "moment";

const CLASS_NAME = "Service_Requests";

const statusEnum = ["Sent", "In-Progress", "Canceled", "Served", "Completed", "Rejected"];

function sorter(serviceRequests){
    const list = [...serviceRequests];

    return list.sort((a, b) => {
        if(a.objectId > b.objectId) return -1;
        if(a.objectId < b.objectId) return 1;
        return 0
    })
}

function ServiceRequestStatus({ status: initialStatus, serviceRequest }){
    const [status, setStatus] = React.useState(initialStatus);
    const { setUpdateObjName, setUpdatedClassData } = useUpdateClass();

    const handleChange = (s) => {
        setUpdateObjName(CLASS_NAME);
        setUpdatedClassData({
            objectId: serviceRequest.objectId,
            StatusTypeCdMeaning: s
        });
        setStatus(s)
    }

    return (
        <Dropdown
            overlay={
                <Menu selectedKeys={[status]}>
                    {
                        statusEnum.map(s => {
                            return <Menu.Item key={s} onClick={() => handleChange(s)}>{s}</Menu.Item>
                        })
                    }
                </Menu>
            }
            trigger={["click"]}
        >
            <Button icon={<CaretDownFilled />}>
                {status}
            </Button>
        </Dropdown>
    )
}

function UserName({ user }){
    const { users } = useGetAllUsers();
    console.log({ users });
    if(!users) return;
    const u = users.find(u => u.objectId === user.objectId)

    return (
        <div>
            {u?.username}
        </div>
    )
}

function CatalogName({ catalog }){
    const { catalogs } = useGetAllCatalogs();
    if(!catalogs) return;
    const c = catalogs.find(c => c.objectId === catalog.objectId)
    return (
        <div>
            {c?.RecordName}
        </div>
    )
}

function BranchName({ branch }){
    const { branches } = useGetAllBranches();
    if(!branches) return;
    const b = branches.find(b => b.objectId === branch.objectId)
    return (
        <div>
            {b?.Branch_c}
        </div>
    )
}


const FIELD_MAPPER = {
    "objectId": "ID",
    "createdAt": "Request Date",
    "updatedAt": "Last Response Date",
    "Title": "Request Title",
    "Tiral_Ar_C": "Request Title Ar",
    "branch": "Branch",
    "catalog": "Catalog",
    "ServiceType_c": "Request Type",
    "PreferredDate_c": "Preferred Date",
    "PreferredTime_c": "Preferred Time",
    "ProblemDescription": "Problem Description",
    "StatusTypeCdMeaning": "Status",
    "user": "User"
}

const columnRenderer = {
    branch: branch => branch && <BranchName branch={branch} />,
    catalog: catalog => catalog && <CatalogName catalog={catalog} />,
    createdAt: createdAt => moment(createdAt).format("DD/MM/YYYY"),
    updatedAt: updatedAt => moment(updatedAt).format("DD/MM/YYYY"),
    StatusTypeCdMeaning: (status, serviceRequest) => {
        return <ServiceRequestStatus status={status} serviceRequest={serviceRequest} />
    },
    user: (user) => {
        return user && <UserName user={user} />
    }
}

function ServiceRequests(){
    return (
        <RealEstateItem 
            realEstateObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            columnRenderer={columnRenderer}
            sorter={sorter}
            hasAddButton={false}
        />
    )
}

export default withPrivate(ServiceRequests);