import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";
import {
    FIELD_MAPPER as AUTHOR_MAPPER,
    CLASS_NAME as AUTHOR_CLASS_NAME,
    formFields as authorFormFields,
    transformAuthorFormValues,
} from "./PostAuthors";
import {
    FIELD_MAPPER as SLUG_MAPPER,
    CLASS_NAME as SLUG_CLASS_NAME,
    formFields as slugFormFields,
    transformSlugFormValues,
} from "./PostSlugs";
import {
    FIELD_MAPPER as MAINIMAGE_MAPPER,
    CLASS_NAME as MAINIMAGE_CLASS_NAME,
    formFields as mainImageFormFields,
    transformMainImageFormValues,
} from "./PostMainImages";

import {
    FIELD_MAPPER as CATEGORY_MAPPER,
    CLASS_NAME as CATEGORY_CLASS_NAME,
    formFields as categoryFormFields,
    transformCategoriesFormValues,
} from "./Categories";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getBlogDeploy } from "../../hooks/post";
import axios from "axios";
import { Button } from "antd";

const CLASS_NAME = "Post";
export const INCLUDE = "";

const FIELD_MAPPER = {
    title: "Post title",
    createdAt: "Post createdAt",
    updatedAt: "Post updatedAt",
    objectId: "objectId",
};

function Posts() {
    const [isDeploying, setIsDeploying] = useState(false);

    const handleDeploy = async () => {
        setIsDeploying(true);
        const { results } = await getBlogDeploy();
        console.log("Blog Deploy Results: ", results);
        const { url } = results[0];
        console.log("Blog Deploy URL: ", url);
        await axios.post(`https://api.netlify.com${url}`, {});
        setIsDeploying(false);
    };

    return (
        <div style={{ padding: "20px 0" }}>
            <div style={{
                position: "absolute",
                right: "20px",
                top: "80px"
            }}>
                <Button
                    loading={isDeploying}
                    disabled={isDeploying}
                    onClick={handleDeploy}
                    type="primary"
                >
                    Deploy
                </Button>
            </div>
            <BlogItem
                blogObjectName={CLASS_NAME}
                mapper={FIELD_MAPPER}
                columnRenderer={{
                    objectId: (id) => (
                        <Link to={`/blog/posts/${id}`}>{id}</Link>
                    ),
                }}
                extraCells={[
                    {
                        Header: "Add Post Components",
                        accessor: "objectId",
                        Cell: (_, post) => {
                            return (
                                <>
                                    <BlogItem
                                        mapper={AUTHOR_MAPPER}
                                        formFields={authorFormFields}
                                        blogObjectName={AUTHOR_CLASS_NAME}
                                        transform={transformAuthorFormValues}
                                        form={{
                                            btn: "Add Author",
                                            disabled: [
                                                "Make_Id",
                                                "Make_c",
                                                "MakeArabic_c",
                                            ],
                                            initialValues: {
                                                Make_c: post.RecordName,
                                                MakeArabic_c: post.MakeArabic_c,
                                                Make_Id: post.objectId,
                                                Year_c: "2022",
                                            },
                                        }}
                                    />
                                    <BlogItem
                                        mapper={MAINIMAGE_MAPPER}
                                        formFields={mainImageFormFields}
                                        blogObjectName={MAINIMAGE_CLASS_NAME}
                                        transform={transformMainImageFormValues}
                                        form={{
                                            btn: "Add MainImage",
                                            disabled: [
                                                "Make_Id",
                                                "Make_c",
                                                "MakeArabic_c",
                                            ],
                                            initialValues: {
                                                Make_c: post.RecordName,
                                                MakeArabic_c: post.MakeArabic_c,
                                                Make_Id: post.objectId,
                                                Year_c: "2022",
                                            },
                                        }}
                                    />
                                    <BlogItem
                                        mapper={SLUG_MAPPER}
                                        formFields={slugFormFields}
                                        blogObjectName={SLUG_CLASS_NAME}
                                        transform={transformSlugFormValues}
                                        form={{
                                            btn: "Add Slug",
                                            disabled: [
                                                "Make_Id",
                                                "Make_c",
                                                "MakeArabic_c",
                                            ],
                                            initialValues: {
                                                Make_c: post.RecordName,
                                                MakeArabic_c: post.MakeArabic_c,
                                                Make_Id: post.objectId,
                                                Year_c: "2022",
                                            },
                                        }}
                                    />
                                    <BlogItem
                                        mapper={CATEGORY_MAPPER}
                                        formFields={categoryFormFields}
                                        blogObjectName={CATEGORY_CLASS_NAME}
                                        transform={
                                            transformCategoriesFormValues
                                        }
                                        form={{
                                            btn: "Add Category",
                                            disabled: [
                                                "Make_Id",
                                                "Make_c",
                                                "MakeArabic_c",
                                            ],
                                            initialValues: {
                                                Make_c: post.RecordName,
                                                MakeArabic_c: post.MakeArabic_c,
                                                Make_Id: post.objectId,
                                                Year_c: "2022",
                                            },
                                        }}
                                    />
                                </>
                            );
                        },
                    },
                ]}
            />
        </div>
    );
}

export default withPrivate(Posts);
