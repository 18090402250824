import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";

export const CLASS_NAME = "MarkDef";
export const INCLUDE = "";

export const FIELD_MAPPER = {
    type: "markdef Type",
    href: "markdef href",
    objectId: " ID",
};

function BlockMarkDef() {
    return <BlogItem blogObjectName={CLASS_NAME} mapper={FIELD_MAPPER} />;
}

export default withPrivate(BlockMarkDef);
