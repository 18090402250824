import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from './RealEstateItem';

export const CLASS_NAME = "paymentPlans";

export const FIELD_MAPPER = {
    "objectId": "objectId",
    "createdAt": "createdAt",
    "updatedAt": "updatedAt",
    "ACL": "ACL",
    "active": "active",
    "default": "default",
    "maxPrice": "maxPrice",
    "minPrice": "minPrice",
    "planName": "planName",
    "propertyId": "propertyId",
    "description": "description",
    "downPayment": "downPayment",
    "paymentDetails": "paymentDetails",
    "installmentsValue": "installmentsValue",
    "catalog": "catalog"
}

export const columnRenderer = {
    catalog: catalog => catalog.objectId
}

export const transformPaymentPlansFormValues = values => {
    const data = { ...values };
    data.catalog = {
        objectId: data.catalog,
        __type:"Pointer",
        className:"Catalogs"
    }
    data.active = true;
    data.default = true;
    return data;
}

function PaymentPlans(){
    return (
        <RealEstateItem 
            realEstateObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            columnRenderer={columnRenderer}
            transform={transformPaymentPlansFormValues}
        />
    )
}

export default withPrivate(PaymentPlans);