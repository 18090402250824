import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";
import {
    CLASS_NAME as ASSETS_CLASS_NAME,
    FIELD_MAPPER as ASSETS_FIELD_MAPPER,
} from "./BlockAssets";
import {
    CLASS_NAME as SPANS_CLASS_NAME,
    FIELD_MAPPER as SPANS_FIELD_MAPPER,
    blockSpanFormFields as blockSpanFormFields,
    transformBlockSpanFormValues,
} from "./BlockSpans";
import {
    CLASS_NAME as MARKDEF_CLASS_NAME,
    FIELD_MAPPER as MARKDEF_FIELD_MAPPER,
} from "./BlockMarkDef";
import {
    CLASS_NAME as PLANS_CLASS_NAME,
    FIELD_MAPPER as PLANS_FIELD_MAPPER,
    transformCategoriesFormValues,
} from "./Categories";

export const CLASS_NAME = "Block";
export const INCLUDE = "children";

export const FIELD_MAPPER = {
    style: "Block Style",
    children: "Block children",
    objectId: "objectId",
};

export const blockFormFields = (function () {
    let arr = [
        ["style", "text"],
        ["text1", "text"],
    ].map(([name, type]) => {
        return {
            name,
            label: FIELD_MAPPER[name],
            type,
        };
    });

    return arr;
})();

export const transformBlockFormValues = (values) => {
    const data = { ...values };

    data.children = [
        {
            objectId: data.text1,
            __type: "Pointer",
            className: "Span",
        },
    ];

    return data;
};

function Blocks() {
    return (
        <BlogItem
            blogObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            formFields={blockFormFields}
            transform={transformBlockFormValues}
            formClassName="col-2"
            extraCells={[
                {
                    Header: "Add Asset",
                    accessor: "objectId",
                    Cell: (_, block) => {
                        return (
                            <BlogItem
                                mapper={ASSETS_FIELD_MAPPER}
                                blogObjectName={ASSETS_CLASS_NAME}
                                form={{
                                    btn: "Add Asset",
                                    initialValues: {},
                                }}
                            />
                        );
                    },
                },
                // {
                //     Header: "Add Span",
                //     accessor: "objectId",
                //     Cell: (_, block) => {
                //         return (
                //             <BlogItem
                //                 mapper={SPANS_FIELD_MAPPER}
                //                 blogObjectName={SPANS_CLASS_NAME}
                //                 formFields={blockSpanFormFields}
                //                 transform={transformBlockSpanFormValues}
                //                 form={{
                //                     btn: "Add Span",
                //                     initialValues: {
                //                     }
                //                 }}
                //             />
                //         )
                //     }
                // },
                {
                    Header: "Add MarkDef",
                    accessor: "objectId",
                    Cell: (_, block) => {
                        return (
                            <BlogItem
                                mapper={MARKDEF_FIELD_MAPPER}
                                blogObjectName={MARKDEF_CLASS_NAME}
                                form={{
                                    btn: "Add MarkDef",
                                    initialValues: {},
                                }}
                            />
                        );
                    },
                },
            ]}
        />
    );
}

export default withPrivate(Blocks);
