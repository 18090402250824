import axios from "axios";
import React from "react";
import { getActiveApp } from "../utils/storage";
import useAsync from "./useAsync";


export default function useDeployBlog(){
    const { run, ...rest } = useAsync();

    const deploy = async () => {
        const app = getActiveApp();
        const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/" + "BlogDeploy";
        const headers = {
            "Idengager-Application-Id": app.backendAppName || app.applicationID,
            "Idengager-Master-Key": app.masterKey,
        };
        const response = await run(axios.get(url, { headers: headers }));
        console.log(response.data?.results)
        return response.data;
    }

    return {
        deploy,
        ...rest
    }
}