import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from './RealEstateItem';

export const CLASS_NAME = "Colors";

export const FIELD_MAPPER = {
    "Catalog_Id": "Unit ID",
    "RecordName": "Option Name EN",
    "ColorNameAr_c": "Option Name AR",
    "ColorCode_c": "Area",
    "CurrencyCode": "Currency Code",
    "RecordNumber": "Price",
    "Image_c": "Option Image"
}

function UnitOptions(){
    return (
        <RealEstateItem 
            realEstateObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
        />
    )
}

export default withPrivate(UnitOptions);