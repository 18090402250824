// import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.css";
import "./assets/css/home.css";
import "./assets/css/header.css";
import "./assets/css/grid.css";
import "./assets/css/boarding.css";
import "./assets/css/login.css";
import "./assets/css/blog.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as ROUTES from "./constants/routes";

import Master from "./components/Master";
import AppLayout from "./components/ConfigureApp/AppLayout";
import * as PAGES from "./pages/index";
import GlobalTour from "./components/GlobalTour";
import GetStartedButton from "./components/GetStartedButton";

function App() {
    return (
        <div>
            <BrowserRouter>
                <GlobalTour />
                {/* <GetStartedButton /> */}
                <Routes>
                    <Route path={ROUTES.LOGIN} element={<PAGES.Login />} />
                    <Route
                        path={ROUTES.BOARDING}
                        element={<PAGES.Boarding />}
                    />
                    <Route
                        path={ROUTES.CREATE_APP}
                        element={<PAGES.Boarding />}
                    />
                    <Route
                        path={ROUTES.HOME}
                        element={<Master content={<PAGES.Home />} />}
                    />

                    <Route
                        path={ROUTES.DEVICES}
                        element={<Master content={<PAGES.Devices />} />}
                    />
                    <Route
                        path={ROUTES.LIST_DEVICES}
                        element={<Master content={<PAGES.ListDevices />} />}
                    />
                    <Route
                        path={ROUTES.RULES}
                        element={<Master content={<PAGES.Rules />} />}
                    />
                    <Route
                        path={ROUTES.LIST_RULES}
                        element={<Master content={<PAGES.ListRules />} />}
                    />
                    <Route
                        path={ROUTES.SESSIONS}
                        element={<Master content={<PAGES.Sessions />} />}
                    />
                    <Route
                        path={ROUTES.LIST_SESSIONS}
                        element={<Master content={<PAGES.ListSessions />} />}
                    />
                    <Route
                        path={ROUTES.USERS}
                        element={<Master content={<PAGES.Users />} />}
                    />
                    <Route
                        path={ROUTES.LIST_USERS}
                        element={<Master content={<PAGES.ListUsers />} />}
                    />
                    <Route
                        path={ROUTES.OBJECTS}
                        element={<Master content={<PAGES.Objects />} />}
                    />
                    <Route
                        path={ROUTES.MESSAGING}
                        element={<Master content={<PAGES.Messaging />} />}
                    />
                    <Route
                        path={ROUTES.MESSAGING_CONFIG}
                        element={<Master content={<PAGES.MessagingConfig />} />}
                    />
                    <Route
                        path={ROUTES.NOTIFICATION}
                        element={
                            <Master content={<PAGES.NotificationHome />} />
                        }
                    />
                    <Route
                        path={ROUTES.PUSH_NOTIFICATION}
                        element={
                            <Master content={<PAGES.PushNotifications />} />
                        }
                    />
                    <Route
                        path={ROUTES.PUSH_MESSAGES}
                        element={<Master content={<PAGES.PushMessages />} />}
                    />
                    <Route
                        path={ROUTES.CHAT_BOT}
                        element={<Master content={<PAGES.ChatBot />} />}
                    />
                    <Route
                        path={ROUTES.SMS}
                        element={<Master content={<PAGES.SMS />} />}
                    />
                    <Route
                        path={ROUTES.EMAIL}
                        element={<Master content={<PAGES.EMAIL />} />}
                    />
                    <Route
                        path={ROUTES.SURVEY}
                        element={<Master content={<PAGES.SURVEY />} />}
                    />
                    <Route
                        path={ROUTES.CAMPAIGN}
                        element={<Master content={<PAGES.CAMPAIGN />} />}
                    />
                    <Route
                        path={ROUTES.CHAT_BOT_CONFIG}
                        element={<Master content={<PAGES.ChatBotConfig />} />}
                    />
                    <Route
                        path={ROUTES.COLORS}
                        element={<Master content={<PAGES.Colors />} />}
                    />
                    <Route
                        path={ROUTES.LOGS}
                        element={<Master content={<PAGES.Logs />} />}
                    />
                    <Route
                        path={ROUTES.LIST_COLORS}
                        element={<Master content={<PAGES.ListColors />} />}
                    />
                    <Route
                        path={ROUTES.LIST_LOGS}
                        element={<Master content={<PAGES.ListLogs />} />}
                    />
                    <Route
                        path={ROUTES.EVENTS}
                        element={<Master content={<PAGES.Events />} />}
                    />
                    <Route
                        path={ROUTES.LIST_EVENTS}
                        element={<Master content={<PAGES.ListEvents />} />}
                    />
                    <Route
                        path={ROUTES.JOBS}
                        element={<Master content={<PAGES.Jobs />} />}
                    />
                    <Route
                        path={ROUTES.LIST_JOBS}
                        element={<Master content={<PAGES.ListJobs />} />}
                    />
                    <Route
                        path={ROUTES.HOOKS}
                        element={<Master content={<PAGES.Hooks />} />}
                    />
                    <Route
                        path={ROUTES.LIST_HOOKS}
                        element={<Master content={<PAGES.ListHooks />} />}
                    />
                    <Route
                        path={ROUTES.LIST_TRIGGERS}
                        element={<Master content={<PAGES.ListTriggers />} />}
                    />
                    <Route
                        path={ROUTES.IMAGES}
                        element={<Master content={<PAGES.Images />} />}
                    />
                    <Route
                        path={ROUTES.LIST_IMAGES}
                        element={<Master content={<PAGES.ListImages />} />}
                    />
                    <Route
                        path={ROUTES.LABELS}
                        element={<Master content={<PAGES.Labels />} />}
                    />
                    <Route
                        path={ROUTES.LIST_LABELS}
                        element={<Master content={<PAGES.ListLabels />} />}
                    />
                    <Route
                        path={ROUTES.SETUP_MESSAGES}
                        element={<Master content={<PAGES.Messages />} />}
                    />
                    <Route
                        path={ROUTES.SETUP_MESSAGES_LIST}
                        element={<Master content={<PAGES.ListMessages />} />}
                    />
                    <Route
                        path={ROUTES.LIST_APPS}
                        element={<PAGES.ListApps />}
                    />

                    <Route
                        path={ROUTES.LIST_OBJECTS}
                        element={<Master content={<PAGES.ListObjects />} />}
                    />
                    <Route
                        path={ROUTES.PROFILE}
                        element={<Master content={<PAGES.Profile />} />}
                    />
                    <Route
                        path={ROUTES.CATALOG}
                        element={<Master content={<PAGES.Catalog />} />}
                    />
                    <Route
                        path={ROUTES.LIST_CATALOGS}
                        element={<Master content={<PAGES.ListCatalog />} />}
                    />
                    <Route
                        path={ROUTES.ORDER}
                        element={<Master content={<PAGES.Order />} />}
                    />
                    <Route
                        path={ROUTES.LIST_ORDERS}
                        element={<Master content={<PAGES.ListOrder />} />}
                    />
                    <Route
                        path={ROUTES.CONFIGURE_APP}
                        element={<AppLayout content={<PAGES.ConfigureApp />} />}
                    />
                    <Route
                        path={ROUTES.GENERAL_SETTING}
                        element={
                            <AppLayout content={<PAGES.GeneralSettings />} />
                        }
                    />
                    <Route
                        path={ROUTES.APP_DESIGN}
                        element={<AppLayout content={<PAGES.AppDesign />} />}
                    />
                    <Route
                        path={ROUTES.APP_BACKEND}
                        element={<AppLayout content={<PAGES.AppBackend />} />}
                    />
                    <Route
                        path={ROUTES.APP_ANALYTICS}
                        element={<AppLayout content={<PAGES.Analytics />} />}
                    />
                    <Route
                        path={ROUTES.PROJECTS}
                        element={<Master content={<PAGES.Projects />} />}
                    />
                    <Route
                        path={ROUTES.PROJECT_STAGES}
                        element={<Master content={<PAGES.ProjectStages />} />}
                    />
                    <Route
                        path={ROUTES.PROJECT_UNITS}
                        element={<Master content={<PAGES.Units />} />}
                    />
                    <Route
                        path={ROUTES.UNIT_OPTIONS}
                        element={<Master content={<PAGES.UnitOptions />} />}
                    />
                    <Route
                        path={ROUTES.UNIT_FEATURES}
                        element={<Master content={<PAGES.UnitFeatures />} />}
                    />
                    <Route
                        path={ROUTES.UNIT_GALLERY}
                        element={<Master content={<PAGES.UnitGallery />} />}
                    />
                    <Route
                        path={ROUTES.PAYMENT_PLANS}
                        element={<Master content={<PAGES.PaymentPlans />} />}
                    />
                    <Route
                        path={ROUTES.SERVICE_REQUESTS}
                        element={<Master content={<PAGES.ServiceRequests />} />}
                    />
                    <Route
                        path={ROUTES.POSTS}
                        element={<Master content={<PAGES.Posts />} />}
                    />
                    <Route
                        path={ROUTES.POST_AUTHORS}
                        element={<Master content={<PAGES.PostAuthors />} />}
                    />
                    <Route
                        path={ROUTES.POST_BLOCKS}
                        element={<Master content={<PAGES.Blocks />} />}
                    />
                    <Route
                        path={ROUTES.BLOCK_SPANS}
                        element={<Master content={<PAGES.BlockSpans />} />}
                    />
                    <Route
                        path={ROUTES.BLOCK_ASSETS}
                        element={<Master content={<PAGES.BlockAssets />} />}
                    />
                    <Route
                        path={ROUTES.BLOCK_MARKDEFS}
                        element={<Master content={<PAGES.BlockMarkDef />} />}
                    />
                    <Route
                        path={ROUTES.POST_CATEGORIES}
                        element={<Master content={<PAGES.Categories />} />}
                    />
                    <Route
                        path={ROUTES.POST_SLUGS}
                        element={<Master content={<PAGES.PostSlugs />} />}
                    />
                    <Route
                        path={ROUTES.POST_MAIN_IMAGE}
                        element={<Master content={<PAGES.PostMainImages />} />}
                    />
                    <Route
                        path={ROUTES.POST_SITESETTINGS}
                        element={<Master content={<PAGES.SiteSettings />} />}
                    />

                    <Route
                        path={ROUTES.POST_DETAILS}
                        element={<Master content={<PAGES.PostDetails />} />}
                    />

                    <Route 
                        path={ROUTES.LEADS}
                        element={<Master content={<PAGES.LeadsPage />} />}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
