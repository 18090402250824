import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from './RealEstateItem';

export const CLASS_NAME = "Gallery";

export const FIELD_MAPPER = {
    "Catalog_Id": "Unit ID",
    "Type_c": "Image Type",
    "Image_c": "Image Type"
}

function UnitGallery(){
    return (
        <RealEstateItem 
            realEstateObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
        />
    )
}

export default withPrivate(UnitGallery);