import {
    Button,
    Dropdown,
    Form,
    Rate,
    Select,
    Space,
    Spin,
    Typography,
} from "antd";
import { withPrivate } from "../components/PrivateRoute";
import Table from "../components/Table";
import { useLeads } from "../hooks/get_leads";
import "../assets/css/leads.css";
import { default_table_item_per_pages } from "./../constants/table_constants";
import { useEffect, useState } from "react";
import {
    CaretDownFilled,
    DownloadOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { getLeadInterest } from "../utils/leads";
import useUpdateClass from "../hooks/update_class";
import useGetAllUsers from "../hooks/get_all_users";
import useDeleteClass from "../hooks/delete_class";
import { CSVLink } from "react-csv";

let allUsers = [];

const statusOptions = [
    { key: "opened", label: "Opened" },
    { key: "closed", label: "Closed" },
    { key: "pending", label: "Pending" },
    { key: "in_progress", label: "In-Progress" },
];

function AddAssigneeButton({ lead }) {
    const { users } = useGetAllUsers();
    const [value, setValue] = useState(lead.assignee);
    const { setUpdateObjName, setUpdatedClassData } = useUpdateClass();

    useEffect(() => {
        if (users) {
            allUsers = users;
        }
    }, [users]);

    const handleChange = (assignee) => {
        setUpdateObjName(
            lead.type === "favorite" ? "favorites" : "Service_Requests"
        );
        setUpdatedClassData({
            objectId: lead.objectId,
            assignee: assignee,
        });
        setValue(assignee);
    };

    return (
        <Select
            onChange={handleChange}
            options={allUsers
                ?.filter((user) => user.type === "internal")
                .map((user) => ({
                    label: user.username,
                    value: user.objectId,
                }))}
            style={{ width: 120 }}
            value={value}
        />
    );
}

function MenuSelect({ options, initialKey, onChange }) {
    const [state, setState] = useState(() => {
        const selected = options.find((option) => option.key === initialKey);
        return selected || options[0];
    });

    return (
        <Dropdown
            menu={{
                items: options,
                onClick: ({ key }) => {
                    const selected = options.find(
                        (option) => option.key === key
                    );
                    setState(selected);
                    onChange?.(selected);
                },
                selectedKeys: [state.key],
            }}
            trigger={["click"]}
        >
            <Button size="small" icon={<CaretDownFilled />}>
                {state.label}
            </Button>
        </Dropdown>
    );
}

function LeadStatus({ lead }) {
    const { setUpdateObjName, setUpdatedClassData } = useUpdateClass();

    const handleChange = ({ key }) => {
        setUpdateObjName(
            lead.type === "service_request" ? "Service_Requests" : "favorites"
        );
        setUpdatedClassData({
            objectId: lead.objectId,
            StatusTypeCdMeaning: key,
        });
    };

    return (
        <MenuSelect
            initialKey={lead.StatusTypeCdMeaning}
            onChange={handleChange}
            options={statusOptions}
        />
    );
}

function LeadRate({ lead }) {
    const { setUpdateObjName, setUpdatedClassData } = useUpdateClass();

    const handleChange = (rating) => {
        setUpdateObjName(
            lead.type === "service_request" ? "Service_Requests" : "favorites"
        );
        setUpdatedClassData({
            objectId: lead.objectId,
            rating,
        });
    };

    return <Rate defaultValue={lead.rating} onChange={handleChange} />;
}

function LeadComment({ lead }) {
    const [comment, setComment] = useState(lead.comment);
    const { setUpdateObjName, setUpdatedClassData } = useUpdateClass();

    const handleChange = (value) => {
        setUpdateObjName(
            lead.type === "service_request" ? "Service_Requests" : "favorites"
        );
        setUpdatedClassData({
            objectId: lead.objectId,
            comment: value,
        });
        setComment(value);
    };

    return (
        <Typography.Paragraph
            editable={{
                onChange: handleChange,
                text: lead.comment,
            }}
            placeholder="Add Comment"
        >
            {comment}
        </Typography.Paragraph>
    );
}

function LeadsCSVLink({ leads }) {
    const headers = [
        { label: "Lead Type", key: "type" },
        { label: "Lead Interest", key: "leadInterest" },
        { label: "Lead Interest Description", key: "leadInterestDescription" },
        { label: "User Email", key: "userEmail" },
        { label: "User Phone", key: "userPhone" },
        { label: "User Name", key: "username" },
        { label: "Lead Status", key: "StatusTypeCdMeaning" },
        { label: "Lead Rating", key: "rating" },
        { label: "Lead Comment", key: "comment" },
    ];

    const data = leads.map((lead) => {
        return {
            ...lead,
            type: lead.type === "favorite" ? "Favorite" : "Service Request",
            userEmail: lead.user?.email,
            userPhone: lead.user?.phone,
            username: lead.user?.username,
            leadInterest: getLeadInterest(lead).type,
            leadInterestDescription: getLeadInterest(lead).description,
        };
    });

    return (
        <CSVLink headers={headers} data={data}>
            <Button icon={<DownloadOutlined />}>Export CSV</Button>
        </CSVLink>
    );
}

function LeadAction({ lead }) {
    const { setUpdateObjName, setUpdatedClassData } = useUpdateClass();

    const handleChange = ({ key }) => {
        setUpdateObjName(
            lead.type === "service_request" ? "Service_Requests" : "favorites"
        );
        setUpdatedClassData({
            objectId: lead.objectId,
            action: key,
        });
    };

    return (
        <MenuSelect
            initialKey={lead.action}
            onChange={handleChange}
            options={[
                { label: "Phone Call", key: "phone_call" },
                { label: "Email", key: "email" },
                { label: "Chat", key: "chat" },
            ]}
        />
    );
}

const columns = [
    {
        Header: "Lead Type",
        accessor: "type",
        Cell: ({ value }) => {
            return value === "favorite" ? "Favorite" : "Service Request";
        },
    },
    {
        Header: "Lead Interest",
        Cell: (_, lead) => {
            const { type } = getLeadInterest(lead);
            return type;
        },
    },
    {
        Header: "Lead Interest Description",
        Cell: (_, lead) => {
            const { description } = getLeadInterest(lead);
            return description;
        },
    },
    {
        Header: "User",
        accessor: "user",
        Cell: ({ value: user }) => {
            if (!user) return "...";
            return (
                <ul style={{ listStyle: "none", padding: "0" }}>
                    <li>
                        <strong>Name:</strong> {user?.phone}
                    </li>
                    <li>
                        <strong>Email:</strong> {user?.email}
                    </li>
                    <li>
                        <strong>Phone:</strong> {user?.phone}
                    </li>
                </ul>
            );
        },
    },
    {
        Header: "Assignee",
        Cell: (_, lead) => <AddAssigneeButton lead={lead} key={lead.objectId} />,
    },
    {
        Header: "Status",
        Cell: (_, lead) => <LeadStatus lead={lead} key={lead.objectId} />,
    },
    {
        Header: "Action",
        Cell: (_, lead) => <LeadAction lead={lead} key={lead.objectId} />,
    },
    {
        Header: "Rate",
        Cell: (_, lead) => <LeadRate lead={lead} key={lead.objectId} />,
    },
    {
        Header: "Comment",
        Cell: (_, lead) => <LeadComment lead={lead} key={lead.objectId} />,
    },
];

const filters = [
    {
        label: "Lead Type",
        fn: (leads, value) => leads.filter((lead) => lead.type === value),
        render: () => (
            <Select
                className="select"
                options={[
                    { label: "Service Request", value: "service_request" },
                    { label: "Favorite", value: "favorite" },
                ]}
            />
        ),
    },
    {
        label: "Lead Interest",
        fn: (leads, value) =>
            leads.filter((lead) => getLeadInterest(lead).type === value),
        render: () => (
            <Select
                className="select"
                options={["Catalog", "Alert", "Promotion"].map((option) => ({
                    label: option,
                    value: option,
                }))}
            />
        ),
    },
    {
        label: "Assignee",
        fn: (leads, value) => leads.filter((lead) => lead.assignee === value),
        render: () => (
            <Select
                className="select"
                options={allUsers.map((user) => ({
                    label: user.username,
                    value: user.objectId,
                }))}
            />
        ),
    },
    {
        label: "Status",
        fn: (leads, value) => leads.filter((lead) => {
            console.log(lead.StatusTypeCdMeaning, value, lead.StatusTypeCdMeaning === value)
            return lead.StatusTypeCdMeaning === value
        }),
        render: () => (
            <Select
                className="select"
                options={statusOptions.map(({ label, key }) => ({
                    label,
                    value: key,
                }))}
            />
        ),
    },
    {
        label: "Rate",
        fn: (leads, value) => leads.filter((lead) => lead.rating === value),
        render: () => (
            <Select className="select" options={[
                { label: "1", value: 1 },
                { label: "2", value: 2 },
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
            ]} />
        )
    }
];

function LeadsTable() {
    const { data, isLoading, isIdle, setData, refresh } = useLeads();
    const [pageCount, setPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(
        default_table_item_per_pages
    );
    const { setClassId, setDeleteObjName } = useDeleteClass();
    const [filter, setFilter] = useState(filters[0]);
    const [search, setSearch] = useState(null);
    const [formRef] = Form.useForm();

    const handlePageClick = (newPageNumber, newItemsPerPage) => {
        setPageCount(newPageNumber);
        setItemsPerPage(newItemsPerPage);
    };

    const handleDelete = (lead) => {
        setClassId(lead.objectId);
        setDeleteObjName(
            lead.type === "favorite" ? "favorites" : "Service_Requests"
        );
        setData(data.filter((item) => item.objectId !== lead.objectId));
    };

    const handleSearch = (values) => {
        setSearch(values.search);
        setPageCount(0);
    };

    const resetSearch = () => {
        setSearch(null);
        setPageCount(0);
        formRef.resetFields(["search"]);
    }

    const filteredData = search ? filter.fn(data || [], search) : data;

    if (isLoading || isIdle) {
        return (
            <div className="Leads__spinner-wrapper">
                <Spin />
            </div>
        );
    }

    return (
        <div>
            <div
                style={{
                    marginBottom: "32px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div className="Leads__filter-bar">
                    <Space>
                        <Dropdown
                            menu={{
                                items: filters.map((filter) => ({
                                    label: filter.label,
                                    key: filter.label,
                                })),
                                onClick: ({ key }) => {
                                    setFilter(
                                        filters.find(
                                            (filter) => filter.label === key
                                        )
                                    );
                                    setSearch(null);
                                },
                            }}
                            trigger={["click"]}
                        >
                            <Button icon={<CaretDownFilled />}>
                                {filter.label}
                            </Button>
                        </Dropdown>

                        <Form
                            onFinish={handleSearch}
                            className="filter-form"
                            key={Date.now()}
                            initialValues={{ search }}
                        >
                            <Form.Item name="search" style={{ margin: 0 }}>
                                {filter.render()}
                            </Form.Item>
                            <Button
                                htmlType="submit"
                                icon={<SearchOutlined />}
                                style={{ margin: "0 10px" }}
                            />
                            {search ? (
                                <Button onClick={resetSearch} size="small">clear</Button>
                            ) : null}
                        </Form>
                    </Space>
                </div>
                <div>
                    <Space>
                        <Button onClick={refresh}>Refresh</Button>
                        <LeadsCSVLink leads={data || []} />
                    </Space>
                </div>
            </div>

            <Table
                columns={columns}
                data={filteredData.slice(
                    pageCount * itemsPerPage,
                    (pageCount + 1) * itemsPerPage
                )}
                size="small"
                pageCount={Math.ceil(data.length / itemsPerPage)}
                total={filteredData.length}
                initItemPerPages={itemsPerPage}
                onPageChange={handlePageClick}
                actions={{
                    delete: true,
                    deleteCallBack: handleDelete,
                }}
            />
        </div>
    );
}

function Leads() {
    return (
        <div className="Leads">
            <LeadsTable />
        </div>
    );
}

export default withPrivate(Leads);
