import { Navigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import Logo from "../assets/images/icloud-ready-logo.png";
import { useAuth } from "../components/AuthProvider";

function Login(){
    const { user } = useAuth();

    if(user){
        return <Navigate to="/" />
    }

    return (
        <div className="login-page">
            <div className="side-wrapper left-side">
                <div>
                    <h2 className="title">Login to idEngager Manager</h2>

                    <LoginForm />
                </div>
            </div>

            <div className="side-wrapper right-side">
                <img src={Logo} alt="icloud ready logo" />
            </div>
        </div>
    )
}

export default Login;