import { Table, Spin } from "antd";
import { FormattedMessage } from "react-intl";
import DataGrid from "../../components/DataGrid";
import { withPrivate } from "../../components/PrivateRoute";
import useGetAllCatalogs from "../../hooks/get_all_catalogs";
// ACCCode_Id: "9G2Wg5zse3"
// ACCCode_c: "111"
// Arabic_Options_c: "Mimix Coffee Cream Candies - 36 Gram"
// Available_c: 16
// Bhp_c: 335
// Brand_Id: "9VhLUVwDal"
// Brand_c: "MiMix"
// Cc_c: 3.6
// City_MPG_c: 11.8
// CurrencyCode: "EGP"
// DRIVE_TYPE_AR_c: "آلي"
// Description_c: "Mimix Coffee Cream Candies - 36 Gram"
// Drive_Type_c: "Mimix Coffee Cream Candies - 36 Gram"
// ENGINE_AR_c: 3.6
// ENGINE_POWER_AR_c: 335
// FUEL_EFFICIENCY_AR_c: 11.8
// Features_c: "Mimix Coffee Cream Candies - 36 Gram"
// GEAR_BOX_AR_c: 10
// Gear_Box_c: 10
// Highway_MPG_c: 10
// Image_c: "https://cdnprod.mafretailproxy.com/sys-master-root/h6b/hff/26493624844318/350922_main.jpg_200Wx200H"
// Make_Id: "hPoHTUCY2K"
// Make_c: "MiMix"
// Model_Id: "4iouT6kljv"
// Model_c: "MiMix"
// New_Car_c: "Y"
// OPTION_CODE_c: "1SD02"
// Options_ShortAR_c: "Mimix Coffee Cream Candies - 36 Gram"
// Options_Short_c: "Mimix Coffee Cream Candies - 36 Gram"
// Options_c: "Mimix Coffee Cream Candies - 36 Gram"
// Price_c: 105.4
// RecordName: "Mimix Coffee Cream Candies"
// RecordNumber: 3139
// SEATING_CAPACITY_AR_c: 5
// Seating_Capacity_c: 5
// Special_Offer_c: "N"
// Year_Id: "fkYIa5PRcB"
// Year_c: 2020
// createdAt: "2022-03-13T17:27:49.568Z"
// objectId: "Gr7BFZiUZu"
// updatedAt: "2022-03-13T17:30:32.272Z"

const columns = [
    {
        title: "Features",
        dataIndex: "Features_c",
        key: "Features_c"
    },
    {
        title: "Record Name",
        dataIndex: "RecordName",
        key: "RecordName"
    },
    {
        title: "Record Number",
        key: "RecordNumber",
        dataIndex: "RecordNumber"
    },
    {
        title: "Engine Power",
        dataIndex: "ENGINE_POWER_AR_c",
        key: "ENGINE_POWER_AR_c"
    },
    {
        title: "Seating Capacity",
        dataIndex: "Seating_Capacity_c",
        key: "Seating_Capacity_c",
    },
    {
        title: "CC",
        dataIndex: "Cc_c",
        key: "Cc_c"
    },
    {
        title: "Price",
        dataIndex: "Price_c",
        key: "Price_c"
    },
    {
        title: "Currency",
        dataIndex: "CurrencyCode",
        key: "CurrencyCode"
    },
    {
        title: "Image",
        dataIndex: "Image_c",
        key: "Image_c",
        render: img => (
            <a href={img}>
                <img src={img} style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%"
                }} />
            </a>
        ) 
    }
]

function ListCatalogs(){
    const { catalogs, loading } = useGetAllCatalogs();

    console.log(catalogs);

    if(loading){
        return <Spin size="large" />
    }

    return (
        <DataGrid
            header={<FormattedMessage id="list-catalogs" defaultMessage="Commerce Catalogs" />}
            content={
                <Table
                    columns={columns}
                    dataSource={catalogs}
                    rowKey="objectId"
                />
            }
        />
    )
}

export default withPrivate(ListCatalogs);