import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from './RealEstateItem';

export const CLASS_NAME = "Specs";

export const FIELD_MAPPER = {
    "Catalog_Id": "Unit ID",
    "type": "Feature Type",
    "image": "Feature Image",
    "title": "Feature Description EN",
    "titleAr": "Feature Description AR",
    "specValue": "Feature Tag"
}

function UnitFeatures(){
    return (
        <RealEstateItem 
            realEstateObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
        />
    )
}

export default withPrivate(UnitFeatures);