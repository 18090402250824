import homeHeaderImg from './../assets/images/home-header-img.svg';
import appBackendIcon from './../icons/app-backend-icon.svg';
import engageIcon from './../icons/engage-icon.svg';
import appSetupIcon from './../icons/app-setup-icon.svg';
import appAnalyticsIcon from './../icons/app-analytics-icon.svg';
import Header from './../components/Header';
import ConfigOverviewCard from './../components/ConfigOverviewCard';
import { Row, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { withPrivate } from "../components/PrivateRoute";
import { setActiveApp } from "./../utils/storage";
import useGetAllApps from '../hooks/get_all_apps';

function Home() {
    let { id } = useParams();
    const { apps } = useGetAllApps();

    useEffect(() => {
        apps.forEach(app => {
            if (app.objectId == id) {
                setActiveApp(app);
                return
            }
        });
    }, [apps, id]);

    return (
        <>
            <Header
                background="linear-gradient(0deg, #92B5C0 0%, #EAFAFF 100%)"
                title="Every Thing is Under your Control"
                description="You can Configure , Manage and Review your all settings regarding your all registered
                             Mobile application , You can user cloud messaging features and push Notifications to your users  "
                buttonTitle="Get Start"
                img={homeHeaderImg}
            />
            <Row className="overview-card-container" justify="center" gutter={[50, 24]}>
                <Col sm={24} md={5} xl={5}>
                    <ConfigOverviewCard
                        background="linear-gradient(164.85deg, #0097C2 4.88%, #004291 89.35%)"
                        icon={appBackendIcon}
                        title="Application BackEnd"
                        description="Build Your Appliation Backend"
                    />
                </Col>
                <Col sm={24} md={5} xl={5}>
                    <ConfigOverviewCard
                        background="linear-gradient(164.85deg, #0097C2 4.88%, #004291 89.35%)"
                        icon={engageIcon}
                        title="Engage"
                        description="Control Installed Devices"
                    />
                </Col>
            </Row>
            <Row className="overview-card-container" justify="center" gutter={[50, 24]}>
                <Col sm={24} md={5} xl={5}>
                    <ConfigOverviewCard
                        background="linear-gradient(164.85deg, #0097C2 4.88%, #004291 89.35%)"
                        icon={appSetupIcon}
                        title="App Live Setup"
                        description="Manage Application Sessions "
                    />
                </Col>
                <Col sm={24} md={5} xl={5}>
                    <ConfigOverviewCard
                        background="linear-gradient(164.85deg, #0097C2 4.88%, #004291 89.35%)"
                        icon={appAnalyticsIcon}
                        title="Analytics"
                        description="View Application Dashboards and Analytics"
                    />
                </Col>
            </Row>
        </>
    );
}

export default withPrivate(Home);