import useGetAllOrders from "../../hooks/get_all_orders";
import { Table } from "antd";
import DataGrid from "../../components/DataGrid";
import { FormattedMessage } from "react-intl";

// Address_Id: "iBQs4h8NTh"
// createdAt: "2022-03-13T21:27:46.844Z"
// objectId: "IaLxEuNacB"
// orderDate: "13 Mar 2022"
// shipping: 0
// status: "In Processing"
// statusDate: "13 Mar 2022"
// totalAmount: 280
// updatedAt: "2022-03-13T21:27:46.844Z"

const columns = [
    {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
    },
    {
        title: "Order Date",
        dataIndex: "orderDate",
        key: "orderDate"
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
    },
    {
        title: "Total Amount",
        dataIndex: "totalAmount",
        key: "totalAmount"
    },
    {
        title: "Status Date",
        dataIndex: "statusDate",
        key: "statusDate"
    }
];

function ListOrders(){
    const { orders, loading } = useGetAllOrders();

    console.log("orders", orders);

    if(loading){
        return <div></div>
    }
    
    return (
        <div className="list-orders-wrapper">
            <DataGrid
                header={<FormattedMessage id="list-orders" defaultMessage="Commerce Orders" />}
                content={
                    <Table 
                        columns={columns}
                        dataSource={orders}
                        rowKey="objectId"
                    />
                }
            />
        </div>
    )
}

export default ListOrders;