import Table from "../../components/Table";
import DataGrid from "../../components/DataGrid";
import ObjectsContainer from "../../components/ObjectsContainer";
import useGetAllObjects from "../../hooks/get_all_objects";
import useGetAllClasses from "../../hooks/get_all_classes";
import React, { useCallback, useEffect, useState } from "react";
import { default_table_item_per_pages } from "../../constants/table_constants";
import { FormattedMessage } from "react-intl";
import { Row, Col, Spin, Form, Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import MainForm from "../../components/MainForm";
import useCreateClass from "../../hooks/create_class";
import { toast } from "react-toastify";
import useDeleteClass from "../../hooks/delete_class";
import useUpdateClass from "../../hooks/update_class";
import useCreateObject from "../../hooks/create_object";

function BlogItem({
    mapper,
    blogObjectName,
    formFields, // [{ name: string, label: string, type: string | number | date }]
    transform, // undefined | (values: { [key]: string }) => { [key: string]: string }
    form, // { btn, initialValues, fields }
    extraCells = [],
    formClassName = "",
    columnRenderer,
    sorter,
    hasAddButton = true,
    onSubmit
}) {
    const { objects, loadingObjects, setReLoadObjects } = useGetAllObjects();
    const { classes, loadingRecords, setClassName } = useGetAllClasses();
    const [columns, setColumns] = useState([]);
    const [activeObject, setActiveObject] = useState(null);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Modal & Form
    const { storeResponse, storeError, setStoreObjName, setClassData } =
        useCreateClass();
    const { storeObjResponse, storeObjError, loadingObj, setObjectData } =
        useCreateObject();
    const {
        updateError,
        updateResponse,
        setUpdateObjName,
        setUpdatedClassData,
    } = useUpdateClass();
    const { setClassId, setDeleteObjName, deleteResponse, deleteError } =
        useDeleteClass();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [newRecordFields, setNewRecordFields] = useState([]);
    const [editableClass, setEditableClass] = useState(null);
    const [formRef] = Form.useForm();

    const getLabel = (value) => mapper[value] || value;

    const handlePageClick = useCallback(
        (newPageNumber, itemsPerPage) => {
            const newOffset = (newPageNumber * itemsPerPage) % classes.length;
            const endOffset = newOffset + itemsPerPage;
            setPageCount(Math.ceil(classes.length / itemsPerPage));
            setCurrentItems(classes.slice(newOffset, endOffset));
        },
        [classes]
    );

    useEffect(() => {
        if (classes) {
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [classes]);

    useEffect(() => {
        if (objects && objects.length > 0) {
            onObjectSelect(
                objects.findIndex(
                    ({ className }) => className === blogObjectName
                )
            );
        }
    }, [objects]);

    // Handle Form Submission Success
    useEffect(() => {
        if (storeResponse) {
            setClassName(activeObject.className);
            toast(
                <FormattedMessage
                    id="store-success"
                    values={{
                        name: (
                            <FormattedMessage
                                id={activeObject.className}
                                defaultMessage={activeObject.className}
                            />
                        ),
                    }}
                    defaultMessage="Success"
                />,
                { type: "success" }
            );
            setPageCount(0);
            setIsVisibleModal(false);
            onSubmit?.(storeResponse);
        }
    }, [storeResponse, activeObject, setClassName]);

    useEffect(() => {
        if (updateResponse) {
            setClassName(activeObject.className);
            toast(
                <FormattedMessage
                    id="update-success"
                    values={{
                        name: (
                            <FormattedMessage
                                id={activeObject.className}
                                defaultMessage={activeObject.className}
                            />
                        ),
                    }}
                    defaultMessage="Success"
                />,
                { type: "success" }
            );
            setPageCount(0);
            setIsVisibleModal(false);
        }
    }, [updateResponse, activeObject, setClassName]);

    useEffect(() => {
        if (deleteResponse) {
            setClassName(activeObject.className);
            toast(
                <FormattedMessage
                    id="delete-success"
                    values={{
                        name: (
                            <FormattedMessage
                                id={activeObject.className}
                                defaultMessage={activeObject.className}
                            />
                        ),
                    }}
                    defaultMessage="Success"
                />,
                { type: "success" }
            );
            setPageCount(0);
        }
    }, [deleteResponse, activeObject, setClassName]);

    useEffect(() => {
        if (storeObjResponse) {
            toast(
                <FormattedMessage
                    id="store-success"
                    values={{
                        name: (
                            <FormattedMessage
                                id="object"
                                defaultMessage="Object"
                            />
                        ),
                    }}
                    defaultMessage="Success"
                />,
                { type: "success" }
            );
            setReLoadObjects(true);
        }
    }, [storeObjResponse]);

    // Handle Objects Requests Errors
    useEffect(() => {
        if (storeError) {
            toast(storeError, { type: "error" });
        }
    }, [storeError]);

    useEffect(() => {
        if (updateError) {
            toast(updateError, { type: "error" });
        }
    }, [updateError]);

    useEffect(() => {
        if (deleteError) {
            toast(deleteError, { type: "error" });
        }
    }, [deleteError]);

    useEffect(() => {
        if (storeObjError) {
            toast(storeObjError, { type: "error" });
        }
    }, [storeObjError]);

    // Handle Table Columns Header According To The Selected Object
    function handleColumns(fields) {
        var result = Object.keys(fields).map((key) => [key, fields[key]]);
        handleFormFields(result);
        let columns = [];
        result.forEach(function (value) {
            if (!mapper[value[0]]) return;
            if (columnRenderer && columnRenderer[value[0]]) {
                columns.push({
                    Header: getLabel(value[0]),
                    accessor: value[0],
                    Cell: (props, record) => {
                        if (columnRenderer && columnRenderer[value[0]]) {
                            return columnRenderer[value[0]](
                                props.value,
                                record,
                                objects,
                                classes
                            );
                        }
                        return props.value ? JSON.stringify(props.value) : "";
                    },
                });
            } else if (value[1].type === "String" || value[1].type === "Number")
                columns.push({
                    Header: getLabel(value[0]),
                    accessor: value[0],
                });
            else if (
                value[1].type === "Date" ||
                value[1].type === "Array" ||
                value[1].type === "ACL" ||
                value[1].type === "Pointer" ||
                value[1].type === "Object" ||
                typeof value[1] === "object"
            ) {
                columns.push({
                    Header: getLabel(value[0]),
                    accessor: value[0],
                    Cell: (props, record) => {
                        if (columnRenderer && columnRenderer[value[0]]) {
                            return columnRenderer[value[0]](
                                props.value,
                                record
                            );
                        }
                        return props.value ? JSON.stringify(props.value) : "";
                    },
                });
            }
        });
        columns = [...columns, ...extraCells];
        setColumns(columns);
    }

    function onObjectSelect(objIndex) {
        let selectedObject = objects[objIndex];
        setPageCount(0);
        handleColumns(selectedObject.fields);
        setActiveObject(selectedObject);
        setClassName(selectedObject.className);
    }

    function onClickDeleteObjects() {}

    // Handle Add (New Record) Form  According To The Selected Object
    function handleFormFields(fields) {
        let addRecordFields = [];
        if (formFields) {
            formFields.forEach((field) => {
                addRecordFields.push({
                    ...field,
                    disabled: !!form?.disabled.includes(field.name),
                    value: form?.initialValues[field.name],
                });
            });
        } else {
            fields.forEach(function (field) {
                if (!mapper[field[0]]) return;
                if (
                    field[0] === "createdAt" ||
                    field[0] === "updatedAt" ||
                    field[0] === "objectId"
                )
                    return;
                let newField = Object.create({});
                newField.name = field[0];
                newField.label = getLabel(field[0]);
                newField.value = "";
                newField.rules = [];
                if (field[1].type === "String") newField.type = "text";
                if (field[1].type === "ACL") newField.type = "textarea";
                else if (field[1].type === "Number") newField.type = "number";
                else if (field[1].type === "Date") newField.type = "date";
                if (
                    form &&
                    form.initialValues &&
                    form.initialValues[newField.name]
                ) {
                    newField.value = form.initialValues[newField.name];
                }
                addRecordFields.push(newField);
            });
        }

        setNewRecordFields(addRecordFields);
    }

    function openAddNewRecordModal() {
        setIsVisibleModal(true);
        // setEditableUser(null);
        formRef.resetFields();
    }

    function onSubmitRecCallBack(formValues) {
        if (transform) {
            formValues = transform(formValues);
        }

        Object.entries(formValues).forEach(([key, value]) => {
            if (/{/.test(value)) {
                formValues[key] = JSON.parse(value);
            }
        });

        if (editableClass) {
            formValues.objectId = editableClass.objectId;
            setUpdatedClassData(formValues);
            setUpdateObjName(activeObject.className);
            setEditableClass(null);
        } else {
            setClassData(formValues);
            setStoreObjName(activeObject.className);
        }
    }

    const onDeleteCallBack = (record) => {
        setClassId(record.objectId);
        setDeleteObjName(activeObject.className);
    };

    const onEditCallBack = (rule) => {
        formRef.setFieldsValue(rule);
        setIsVisibleModal(true);
        setEditableClass(rule);
    };

    React.useEffect(() => {
        if (form) {
            formRef.setFieldsValue(form.initialValues);
        }
    }, []);

    if (form) {
        return (
            <>
                <Modal
                    title={
                        <FormattedMessage
                            id="dataGrid-add-btn-title"
                            defaultMessage="Add New"
                        />
                    }
                    visible={isVisibleModal}
                    footer={null}
                    initialValues={form.initialValues}
                    onCancel={() => {
                        setEditableClass(null);
                        setIsVisibleModal(false);
                    }}
                >
                    <MainForm
                        formName="add-new-record"
                        formRef={formRef}
                        fields={newRecordFields}
                        onSubmit={onSubmitRecCallBack}
                        className={formClassName}
                    />
                </Modal>

                <Button
                    onClick={openAddNewRecordModal}
                    className="filter-button"
                    type="success"
                    size={15}
                >
                    {form.btn}
                </Button>
            </>
        );
    }

    return (
        <DataGrid
            header={
                <FormattedMessage
                    id="dataGrid-posts-management"
                    defaultMessage="Posts Management"
                />
            }
            addNewBTN={hasAddButton}
            addNewBtnOnClick={openAddNewRecordModal}
            content={
                loadingObjects || loadingObj ? (
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                ) : (
                    <div>
                        {loadingRecords ? (
                            <div className="spinner">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <>
                                <Table
                                    columns={columns}
                                    data={
                                        sorter
                                            ? sorter(currentItems)
                                            : currentItems
                                    }
                                    pageCount={pageCount}
                                    total={classes.length}
                                    onPageChange={handlePageClick}
                                    initItemPerPages={
                                        default_table_item_per_pages
                                    }
                                    actions={{
                                        delete: true,
                                        deleteCallBack: onDeleteCallBack,
                                        deleteModelName: (
                                            <FormattedMessage
                                                id={
                                                    activeObject?.className ||
                                                    "record"
                                                }
                                                defaultMessage={
                                                    activeObject?.className ||
                                                    "Record"
                                                }
                                            />
                                        ),
                                        edit: true,
                                        editCallBack: onEditCallBack,
                                    }}
                                />
                                <Modal
                                    title={
                                        <FormattedMessage
                                            id="dataGrid-add-btn-title"
                                            defaultMessage="Add New"
                                        />
                                    }
                                    visible={isVisibleModal}
                                    footer={null}
                                    onCancel={() => {
                                        setEditableClass(null);
                                        setIsVisibleModal(false);
                                    }}
                                >
                                    <MainForm
                                        formName="add-new-record"
                                        formRef={formRef}
                                        fields={newRecordFields}
                                        onSubmit={onSubmitRecCallBack}
                                        className={formClassName}
                                    />
                                </Modal>
                            </>
                        )}
                    </div>
                )
            }
        ></DataGrid>
    );
}

export default BlogItem;
