import React from "react";
import { Dropdown, Menu, Button } from "antd";
import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";
import useUpdateClass from "../../hooks/update_class";
import { CaretDownFilled } from "@ant-design/icons";
import useGetAllUsers from "../../hooks/get_all_users";
import useGetAllCatalogs from "../../hooks/get_all_catalogs";
import useGetAllBranches from "../../hooks/get_all_branches";
import moment from "moment";

const CLASS_NAME = "SiteSetting";
export const INCLUDE = "";

function sorter(siteSettings) {
    const list = [...siteSettings];

    return list.sort((a, b) => {
        if (a.objectId > b.objectId) return -1;
        if (a.objectId < b.objectId) return 1;
        return 0;
    });
}

const FIELD_MAPPER = {
    objectId: "ID",
    createdAt: "Request Date",
    updatedAt: "Last Response Date",
    description: "description",
    title: "title",
    keywords: "keywords",
};

const columnRenderer = {
    createdAt: (createdAt) => moment(createdAt).format("DD/MM/YYYY"),
    updatedAt: (updatedAt) => moment(updatedAt).format("DD/MM/YYYY"),
};

function SiteSettings() {
    return (
        <BlogItem
            blogObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            columnRenderer={columnRenderer}
            sorter={sorter}
            hasAddButton={false}
        />
    );
}

export default withPrivate(SiteSettings);
