import iCloudLogo from './../assets/images/logo.svg';
import homeIcon from './../icons/home.svg';
import devicesIcon from './../icons/devices-icon.svg';
import rulesIcon from './../icons/rules-icon.svg';
import sessionsIcon from './../icons/sessions-icon.svg';
import usersIcon from './../icons/users-icon.svg';
import objectsIcon from './../icons/objects-icon.svg';
import inAppIcon from './../icons/in-app-messaging-icon.svg';
import pushNotificationsIcon from './../icons/push-notifications-icon.svg';
import chatBotsIcon from './../icons/chat-bots-icon.svg';
import smsIcon from './../icons/sms-icon.svg';
import emailIcon from './../icons/email-icon.svg';
import surveyIcon from './../icons/survey-icon.svg';
import campaignIcon from './../icons/campaign-icon.svg';
import colorsIcon from './../icons/colors-icon.svg';
import imagesIcon from './../icons/images-icon.svg';
import labelsIcon from './../icons/labels-icon.svg';
import { Layout, Menu } from 'antd';
import * as ROUTES from './../constants/routes';
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl'
import useGetAppInfo from '../hooks/get_app_info';
import useGetAllClasses from '../hooks/get_all_classes';
import useGetAllObjects from '../hooks/get_all_objects';

function read(obj, nestedFields) {
    for (let field of nestedFields.split(".")) {
        if (!obj && typeof obj !== "object") return obj;
        obj = obj[field];
    }

    return obj;
}

function isAppFeature(appInfo, feature) {
    if (!appInfo) return true;
    return read(appInfo, feature) !== false
}

function doesObjectExist(objects, objectName) {
    if (!objects) return true;
    return !!objects.find(obj => obj.className === objectName);
}

function SideMenu(props) {
    const { Sider } = Layout;
    const { SubMenu } = Menu;
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openSubmenuKeys, setOpenSubmenuKeys] = useState(["real-estate"]);
    const navigate = useNavigate();
    const { data: appInfo } = useGetAppInfo();
    const { objects } = useGetAllObjects();

    // Handle Current Route Active
    useEffect(() => {
        if (!ROUTES.INTERNAL_LINKS.includes(location.pathname))
            setSelectedKeys([location.pathname]);
    }, [location.pathname]);

    // Handle Current UnCollapsed Sub-Menu
    useEffect(() => {
        if (selectedKeys.includes(ROUTES.DEVICES) || selectedKeys.includes(ROUTES.RULES) ||
            selectedKeys.includes(ROUTES.SESSIONS) || selectedKeys.includes(ROUTES.USERS) ||
            selectedKeys.includes(ROUTES.OBJECTS)) {
            setOpenSubmenuKeys(['backend-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.MESSAGING) || selectedKeys.includes(ROUTES.NOTIFICATION) ||
            selectedKeys.includes(ROUTES.CHAT_BOT)) {
            setOpenSubmenuKeys(['engage-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.COLORS) || selectedKeys.includes(ROUTES.IMAGES) ||
            selectedKeys.includes(ROUTES.LABELS) || selectedKeys.includes(ROUTES.SETUP_MESSAGES)) {
            setOpenSubmenuKeys(['app-setup-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.LOGS) || selectedKeys.includes(ROUTES.EVENTS)) {
            setOpenSubmenuKeys(['app-analytics-sub-menu']);
        }
        else if (selectedKeys.includes(ROUTES.JOBS) || selectedKeys.includes(ROUTES.EVENTS)) {
            setOpenSubmenuKeys(['app-integrations-sub-menu']);
        } else if (selectedKeys.includes(ROUTES.PROJECTS) || selectedKeys.includes(ROUTES.PROJECT_STAGES) ||
            selectedKeys.includes(ROUTES.PROJECT_UNITS) || selectedKeys.includes(ROUTES.UNIT_OPTIONS) ||
            selectedKeys.includes(ROUTES.UNIT_FEATURES) || selectedKeys.includes(ROUTES.UNIT_GALLERY) ||
            selectedKeys.includes(ROUTES.PAYMENT_PLANS) || selectedKeys.includes(ROUTES.SERVICE_REQUESTS)
        ) {
            setOpenSubmenuKeys(["real-estate"])
        }
    }, [selectedKeys]);

    // Handle Routes Redirects Navigations
    function redirect(route) {
        navigate(route);
    }

    // Handle Sub-Menu Click
    function openSubmenu(key) {
        if (openSubmenuKeys.includes(key)) // Hanlde Sub-Menu Collapse
            setOpenSubmenuKeys([]);
        else   // Hanlde Sub-Menu Un-Collapse
            setOpenSubmenuKeys([key]);
    }


    return (
        <Sider className="side-menu" trigger={null} collapsible collapsed={props.collapsed}>
            <div id="logo" className="logo">
                <img src={iCloudLogo} alt="icloud-logo" />
            </div>
            <Menu className="menu" theme="dark" selectedKeys={selectedKeys} openKeys={openSubmenuKeys} mode="inline">
                <Menu.Item className="menu-item" onClick={e => redirect(ROUTES.HOME)} key={ROUTES.HOME} icon={<img src={homeIcon} alt="home-icon" />}>
                    <FormattedMessage id='sideMenu-home' defaultMessage="Home" />
                </Menu.Item>
                <Menu.Item className="menu-item" onClick={e => redirect(ROUTES.LIST_APPS)} key={ROUTES.LIST_APPS}>
                    <FormattedMessage id="all-apps" defaultMessage="All Apps" />
                </Menu.Item>
                <SubMenu onTitleClick={e => openSubmenu("backend-sub-menu")} className="menu-item" key="backend-sub-menu" title="Backend">
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.DEVICES)} key={ROUTES.DEVICES} icon={<img src={devicesIcon} alt="devices-icon" />}>
                        <FormattedMessage id='sideMenu-devices' defaultMessage="Devices" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.RULES)} key={ROUTES.RULES} icon={<img src={rulesIcon} alt="rules-icon" />}>
                        <FormattedMessage id='sideMenu-rules' defaultMessage="Rules" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.SESSIONS)} key={ROUTES.SESSIONS} icon={<img src={sessionsIcon} alt="sessions-icon" />}>
                        <FormattedMessage id='sideMenu-sessions' defaultMessage="Sessions" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.USERS)} key={ROUTES.USERS} icon={<img src={usersIcon} alt="users-icon" />}>
                        <FormattedMessage id='sideMenu-users' defaultMessage="Users" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.OBJECTS)} key={ROUTES.OBJECTS} icon={<img src={objectsIcon} alt="objects-icon" />}>
                        <FormattedMessage id='sideMenu-objects' defaultMessage="Objects" />
                    </Menu.Item>
                </SubMenu>
                <SubMenu onTitleClick={e => openSubmenu("engage-sub-menu")} className="menu-item" key="engage-sub-menu" title="Engage">
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.MESSAGING)} key={ROUTES.MESSAGING} icon={<img src={inAppIcon} alt="in-app-messaging-icon" />}>
                        <FormattedMessage id='sideMenu-app-messaging' defaultMessage="In App Messaging" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.NOTIFICATION)} key={ROUTES.NOTIFICATION} icon={<img src={pushNotificationsIcon} alt="push-notifications-icon" />}>
                        <FormattedMessage id='sideMenu-push-notification' defaultMessage="Push Notification" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.CHAT_BOT)} key={ROUTES.CHAT_BOT} icon={<img src={chatBotsIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='sideMenu-chat-bots' defaultMessage="Chat Bots" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.SMS)} key={ROUTES.SMS} icon={<img src={smsIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='sms' defaultMessage="SMS" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.EMAIL)} key={ROUTES.EMAIL} icon={<img src={emailIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='email' defaultMessage="Email" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.SURVEY)} key={ROUTES.SURVEY} icon={<img src={surveyIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='survey' defaultMessage="Survey" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.CAMPAIGN)} key={ROUTES.CAMPAIGN} icon={<img src={campaignIcon} alt="chat-bots-icon" />}>
                        <FormattedMessage id='campaign' defaultMessage="Campaign" />
                    </Menu.Item>
                </SubMenu>

                {/* App Config SubMenu */}
                <SubMenu id="app-setup" onTitleClick={e => openSubmenu("app-setup-sub-menu")} className="menu-item" key="app-setup-sub-menu" title="App Setup">
                    {isAppFeature(appInfo, "features.liveconfiguration.hasLiveconfigurationColors") &&
                        <Menu.Item id="app-colors" className="sub-menu-item" onClick={e => redirect(ROUTES.COLORS)} key={ROUTES.COLORS} icon={<img src={colorsIcon} alt="colors-icon" />}>
                            <FormattedMessage id='sideMenu-colors' defaultMessage="Colors" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.liveconfiguration.hasLiveconfigurationImages") &&
                        <Menu.Item id="app-images" className="sub-menu-item" onClick={e => redirect(ROUTES.IMAGES)} key={ROUTES.IMAGES} icon={<img src={imagesIcon} alt="images-icon" />}>
                            <FormattedMessage id='sideMenu-images' defaultMessage="Images" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.liveconfiguration.hasLiveconfigurationLabels") &&
                        <Menu.Item id="app-labels" className="sub-menu-item" onClick={e => redirect(ROUTES.LABELS)} key={ROUTES.LABELS} icon={<img src={labelsIcon} alt="labels-icon" />}>
                            <FormattedMessage id='sideMenu-labels' defaultMessage="Labels" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.liveconfiguration.hasLiveconfigurationMessages") &&
                        <Menu.Item id="app-messages" className="sub-menu-item" onClick={e => redirect(ROUTES.SETUP_MESSAGES)} key={ROUTES.SETUP_MESSAGES} icon={<img src={inAppIcon} alt="messages-icon" />}>
                            <FormattedMessage id='sideMenu-messages' defaultMessage="Messages" />
                        </Menu.Item>}
                </SubMenu>

                <SubMenu onTitleClick={e => openSubmenu("app-analytics-sub-menu")} className="menu-item" key="app-analytics-sub-menu" title="App Analytics">
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.EVENTS)} key={ROUTES.EVENTS} icon={<img src={colorsIcon} alt="events-icon" />}>
                        <FormattedMessage id='sideMenu-events' defaultMessage="EVENTS" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.LOGS)} key={ROUTES.IMAGES} icon={<img src={imagesIcon} alt="logs-icon" />}>
                        <FormattedMessage id='sideMenu-logs' defaultMessage="Logs" />
                    </Menu.Item>

                </SubMenu>
                <SubMenu onTitleClick={e => openSubmenu("app-integrations-sub-menu")} className="menu-item" key="app-integrations-sub-menu" title="App Integrations">
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.JOBS)} key={ROUTES.JOBS} icon={<img src={colorsIcon} alt="jobs-icon" />}>
                        <FormattedMessage id='sideMenu-jobs' defaultMessage="JOBS" />
                    </Menu.Item>
                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.HOOKS)} key={ROUTES.HOOKS} icon={<img src={imagesIcon} alt="hooks-icon" />}>
                        <FormattedMessage id='sideMenu-hooks' defaultMessage="Hooks" />
                    </Menu.Item>
                </SubMenu>

                {/* E-Commerce SubMenu */}
                <SubMenu onTitleClick={e => openSubmenu("commerce-integrations-sub-menu")} className="menu-item" key="commerce-integrations-sub-menu" title="E-Commerce">
                    {isAppFeature(appInfo, "features.ecommerce.ecommerceCatalog") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.CATALOG)} key={ROUTES.CATALOG} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='catalog' defaultMessage="Catalog" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.ecommerce.ecommerceCustomer") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.CUSTOMER)} key={ROUTES.CUSTOMER} icon={<img src={objectsIcon} alt="customer-icon" />}>
                            <FormattedMessage id='customer' defaultMessage="Customer" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.ecommerce.ecommerceOrder") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.ORDER)} key={ROUTES.ORDER} icon={<img src={objectsIcon} alt="order-icon" />}>
                            <FormattedMessage id='order' defaultMessage="Order" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.ecommerce.ecommerceDiscount") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.HOOKS)} key={ROUTES.HOOKS} icon={<img src={objectsIcon} alt="discount-icon" />}>
                            <FormattedMessage id='discount' defaultMessage="Discount" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.ecommerce.ecommerceLoyalty") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.HOOKS)} key={ROUTES.HOOKS} icon={<img src={objectsIcon} alt="loyalty-icon" />}>
                            <FormattedMessage id='loyalty' defaultMessage="Loyalty" />
                        </Menu.Item>}
                </SubMenu>

                {/* Real-estate submenu */}
                <SubMenu id="realestate-sidemenu-item" onTitleClick={e => openSubmenu("real-estate")} className="menu-item" key="real-estate" title="Real Estate">
                    {isAppFeature(appInfo, "features.realstate.realstateProject") &&
                        <Menu.Item id="realestate-projects" className="sub-menu-item" onClick={e => redirect(ROUTES.PROJECTS)} key={ROUTES.PROJECTS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='real-estate-projects' defaultMessage="Projects" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.realstate.realstateStage") &&
                        <Menu.Item id="realestate-stages" className="sub-menu-item" onClick={e => redirect(ROUTES.PROJECT_STAGES)} key={ROUTES.PROJECT_STAGES} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='real-estate-projects' defaultMessage="Project Stages" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.realstate.realstateUnit") &&
                        <Menu.Item id="realestate-units" className="sub-menu-item" onClick={e => redirect(ROUTES.PROJECT_UNITS)} key={ROUTES.PROJECT_UNITS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='real-estate-projects' defaultMessage="Project Units" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.realstate.realstateOption") &&
                        <Menu.Item id="realestate-units" className="sub-menu-item" onClick={e => redirect(ROUTES.UNIT_OPTIONS)} key={ROUTES.UNIT_OPTIONS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='real-estate-projects' defaultMessage="Unit Options" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.realstate.realstateFeature") &&
                        <Menu.Item id="realestate-units" className="sub-menu-item" onClick={e => redirect(ROUTES.UNIT_FEATURES)} key={ROUTES.UNIT_FEATURES} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='real-estate-projects' defaultMessage="Unit Features" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.realstate.realstateGallery") &&
                        <Menu.Item id="realestate-units" className="sub-menu-item" onClick={e => redirect(ROUTES.UNIT_GALLERY)} key={ROUTES.UNIT_GALLERY} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='real-estate-projects' defaultMessage="Unit Gallery" />
                        </Menu.Item>}

                    {doesObjectExist(objects, "paymentPlans") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.PAYMENT_PLANS)} key={ROUTES.PAYMENT_PLANS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='real-estate-paymentPlans' defaultMessage="Payment Plans" />
                        </Menu.Item>}

                    <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.SERVICE_REQUESTS)} key={ROUTES.SERVICE_REQUESTS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                        <FormattedMessage id='real-estate-serviceRequests' defaultMessage="Service Requests" />
                    </Menu.Item>
                </SubMenu>

                {/* Blog submenu */}
                <SubMenu onTitleClick={e => openSubmenu("blog")} className="menu-item" key="blog" title="Blog">
                    {isAppFeature(appInfo, "features.blog.blogPost") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.POSTS)} key={ROUTES.POSTS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-posts' defaultMessage="Posts" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.blogAuthor") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.POST_AUTHORS)} key={ROUTES.POST_AUTHORS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-blogAuthor' defaultMessage="Post Authors" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.blogBlock") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.POST_BLOCKS)} key={ROUTES.POST_BLOCKS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-blogBlock' defaultMessage="Post Blocks" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.blockSpan") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.BLOCK_SPANS)} key={ROUTES.BLOCK_SPANS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-blockSpan' defaultMessage="Block Spans" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.blockAsset") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.BLOCK_ASSETS)} key={ROUTES.BLOCK_ASSETS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-blockAsset' defaultMessage="Block Assets" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.blockMarkDef") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.BLOCK_MARKDEFS)} key={ROUTES.BLOCK_MARKDEFS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-blockMarkDef' defaultMessage="Block MarkDef" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.mainImage") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.POST_MAIN_IMAGE)} key={ROUTES.POST_MAIN_IMAGE} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-mainImage' defaultMessage="Post Main Images" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.categories") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.POST_CATEGORIES)} key={ROUTES.POST_CATEGORIES} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-categories' defaultMessage="Posts Categories" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.slug") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.POST_SLUGS)} key={ROUTES.POST_SLUGS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-slug' defaultMessage="Slugs" />
                        </Menu.Item>}

                    {isAppFeature(appInfo, "features.blog.siteSetting") &&
                        <Menu.Item className="sub-menu-item" onClick={e => redirect(ROUTES.POST_SITESETTINGS)} key={ROUTES.POST_SITESETTINGS} icon={<img src={objectsIcon} alt="catalog-icon" />}>
                            <FormattedMessage id='blog-siteSetting' defaultMessage="Site Setting" />
                        </Menu.Item>}
                </SubMenu>

                <Menu.Item className="menu-item" onClick={e => redirect(ROUTES.LEADS)} key={ROUTES.LEADS}>
                    <FormattedMessage id='leads' defaultMessage="Leads" />
                </Menu.Item>
            </Menu>
        </Sider>
    );
}

export default SideMenu;
