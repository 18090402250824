import { EditorState, ContentState, convertFromHTML } from "draft-js";

export const randomId = () => {
    return Date.now() + Math.floor(Math.random() * 100000);
};

export function reducer(state, action) {
    switch (action.type) {
        case "set_content":
            return { ...state, content: action.content };
        case "set_title":
            return { ...state, title: action.title };
            break;
        case "set_slug":
            return { ...state, slug: { ...state.slug, ...action.data } };
            break;
        case "set_main_image":
            return {
                ...state,
                mainImage: { ...state.mainImage, ...action.data },
            };
            break;
        case "add_category": // { type, data }
            return {
                ...state,
                categories: [
                    ...state.categories,
                    {
                        objectId: randomId(),
                        className: "Category",
                        __type: "Object",
                        ...action.data,
                    },
                ],
            };
            break;
        case "remove_category": // { categoryId }
            return {
                ...state,
                categories: state.categories.filter(
                    (c) => c.objectId !== action.categoryId
                ),
            };
        case "update_category": // { categoryId, type, data }
            return {
                ...state,
                categories: state.categories.map((c) => {
                    if (c.objectId !== action.categoryId) return c;
                    return {
                        ...c,
                        ...action.data,
                    };
                }),
            };
        case "add_author": // { type, data }
            return {
                ...state,
                authors: [
                    ...state.authors,
                    {
                        objectId: randomId(),
                        className: "Author",
                        __type: "Object",
                        bio: [],
                        name: "",
                        country: "",
                        image: {
                            objectId: randomId(),
                            externalurl: "",
                            className: "AuthorImage",
                            __type: "Object",
                            alt: "",
                        },
                        ...action.data,
                    },
                ],
            };
            break;
        case "remove_author": // { authorId }
            return {
                ...state,
                authors: state.authors.filter(
                    (a) => a.objectId !== action.authorId
                ),
            };
        case "update_author": // { authorId, type, data }
            return {
                ...state,
                authors: state.authors.map((a) => {
                    if (a.objectId !== action.authorId) return a;
                    return {
                        ...a,
                        ...action.data,
                    };
                }),
            };
        case "add_author_bio_block": // { authorId, type }
            return {
                ...state,
                authors: state.authors.map((a) => {
                    if (a.objectId !== action.authorId) return a;
                    return {
                        ...a,
                        bio: [
                            ...a.bio,
                            {
                                className: "Block",
                                objectId: randomId(),
                                __type: "Object",
                                children: [],
                            },
                        ],
                    };
                }),
            };
            break;
        case "remove_author_bio_block": // { authorId, type, blockId }
            return {
                ...state,
                authors: state.authors.map((a) => {
                    if (a.objectId !== action.authorId) return;
                    return {
                        ...a,
                        bio: a.bio.filter(
                            (block) => block.objectId !== action.blockId
                        ),
                    };
                }),
            };
            break;
        case "add_author_bio_span": // { authorId, blockId, type }
            return {
                ...state,
                authors: state.authors.map((a) => {
                    if (a.objectId !== action.authorId) return a;
                    return {
                        ...a,
                        bio: a.bio.map((block) => {
                            if (action.blockId !== block.objectId) return block;
                            return {
                                ...block,
                                children: [
                                    ...block.children,
                                    {
                                        objectId: randomId(),
                                        className: "Span",
                                        __type: "Object",
                                        text: "",
                                    },
                                ],
                            };
                        }),
                    };
                }),
            };
            break;
        case "remove_author_bio_span": // { authorId, blockId, spanId }
            return {
                ...state,
                authors: state.authors.map((a) => {
                    if (a.objectId !== action.authorId) return a;
                    return {
                        ...a,
                        bio: a.bio.map((block) => {
                            if (action.blockId !== block.objectId) return block;
                            return {
                                ...block,
                                children: block.children.filter(
                                    (span) => span.objectId !== action.spanId
                                ),
                            };
                        }),
                    };
                }),
            };
            break;
        case "update_author_bio_span": // { authorId, blockId, spanId, data }
            return {
                ...state,
                authors: state.authors.map((a) => {
                    if (a.objectId !== action.authorId) return a;
                    return {
                        ...a,
                        bio: a.bio.map((block) => {
                            if (action.blockId !== block.objectId) return block;
                            return {
                                ...block,
                                children: block.children.map((span) => {
                                    if (span.objectId !== action.spanId)
                                        return span;
                                    return {
                                        ...span,
                                        ...action.data,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
            break;
        case "add_block": // { type, data }
            return {
                ...state,
                body: [
                    ...state.body,
                    {
                        objectId: randomId(),
                        className: "Block",
                        __type: "Object",
                        style: "normal",
                        children: [],
                    },
                ],
            };
            break;
        case "remove_block": // { blockId }
            return {
                ...state,
                body: state.body.filter((b) => b.objectId !== action.blockId),
            };
        case "add_span": // { blockId, span }
            return {
                ...state,
                body: state.body.map((block) => {
                    if (block.objectId !== action.blockId) return block;
                    return {
                        ...block,
                        children: [
                            ...block.children,
                            {
                                objectId: randomId(),
                                className: "Span",
                                __type: "Object",
                                marks: null,
                                text: "",
                            },
                        ],
                    };
                }),
            };
            break;
        case "update_span": // { blockId, spanId, data, type }
            return {
                ...state,
                body: state.body.map((block) => {
                    if (block.objectId !== action.blockId) return block;
                    return {
                        ...block,
                        children: block.children.map((child) => {
                            if (child.objectId !== action.spanId) return child;
                            return {
                                ...child,
                                ...action.data,
                            };
                        }),
                    };
                }),
            };
            break;
        case "remove_span": // { blockId, spanId }
            return {
                ...state,
                body: state.body.map((block) => {
                    if (block.objectId !== action.blockId) return block;
                    return {
                        ...block,
                        children: block.children.filter((child) => {
                            return child.objectId !== action.spanId;
                        }),
                    };
                }),
            };
            break;
        case "add_excerpt_block": // { type, data }
            return {
                ...state,
                excerpt: [
                    ...state.excerpt,
                    {
                        objectId: randomId(),
                        className: "Block",
                        __type: "Object",
                        style: "normal",
                        children: [],
                    },
                ],
            };
            break;
        case "remove_excerpt_block": // { blockId }
            return {
                ...state,
                excerpt: state.excerpt.filter(
                    (b) => b.objectId !== action.blockId
                ),
            };
        case "add_excerpt_span": // { blockId, span }
            return {
                ...state,
                excerpt: state.excerpt.map((block) => {
                    if (block.objectId !== action.blockId) return block;
                    return {
                        ...block,
                        children: [
                            ...block.children,
                            {
                                objectId: randomId(),
                                className: "Span",
                                __type: "Object",
                                text: "",
                            },
                        ],
                    };
                }),
            };
            break;
        case "update_excerpt_span": // { blockId, spanId, data, type }
            return {
                ...state,
                excerpt: state.excerpt.map((block) => {
                    if (block.objectId !== action.blockId) return block;
                    return {
                        ...block,
                        children: block.children.map((child) => {
                            if (child.objectId !== action.spanId) return child;
                            return {
                                ...child,
                                ...action.data,
                            };
                        }),
                    };
                }),
            };
            break;
        case "remove_excerpt_span": // { blockId, spanId }
            return {
                ...state,
                excerpt: state.excerpt.map((block) => {
                    if (block.objectId !== action.blockId) return block;
                    return {
                        ...block,
                        children: block.children.filter((child) => {
                            return child.objectId !== action.spanId;
                        }),
                    };
                }),
            };
            break;
        default:
            throw new Error(`Unsupported action type: ${action.type}`);
    }
}

export const initialPost = {
    body: [],
    categories: [],
    mainImage: {
        objectId: randomId(),
        className: "Slug",
        externalurl: "",
        alt: "",
    },
    authors: [],
    excerpt: [],
    title: "",
    slug: {
        objectId: randomId(),
        className: "Slug",
        current: "",
    },
};

export const getDraftStateFromHTML = (html) => {
    if(!html) return EditorState.createEmpty();
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
};
