import React, { useEffect } from "react";
import { Storytime } from "@icloudready/storytime";
import { ChatWidget } from "@icloudready/chat-widget";
import { useAuth } from "../AuthProvider";
import useGetAllApps from '../../hooks/get_all_apps';

//import styled from "styled-components";

//import Button from "components/Button";

type IdengagerchatConfig = {
  accountId: string;
  baseUrl: string;
  enableStorytime: boolean;
  debug: boolean;
};

type IProps = {
  idengagerchatConfig: IdengagerchatConfig;
  customer: {name:string,email:string,external_id:string,  metadata?: {[key: string]: any}};
  customerApps?: {[key: string]: any};
  onClick?: () => void;
};
//
// const ChatButton = styled(Button)`
//   width: 50px;
//   height: 50px;

//   border-radius: 50%;
//   padding: 0;

//   position: fixed;
//   bottom: 25px;
//   right: 25px;

//   font-size: 30px;
// `;

const SupportChat: React.FC<IProps> = ({
  idengagerchatConfig,
  customer,
  customerApps
}) => {
    let { user } =  useAuth();

  const { apps } = useGetAllApps();

  useEffect(() => {
    if (idengagerchatConfig.enableStorytime ) {
      Storytime.init({
        accountId: idengagerchatConfig.accountId,
        baseUrl: idengagerchatConfig.baseUrl,
        customer: {
          name: customer?.name || user?.username ,
          email: customer?.email ||user?.email ,
          external_id: customer?.external_id || user?.objectId,
         metadata: customer?.metadata || apps[0]
        },
        debug: idengagerchatConfig.debug
      });
    }
  }, [customer, customerApps,idengagerchatConfig]);

  return (
    // <ChatButton onClick={onClick}>
    //   <FontAwesomeIcon icon={faComment} />
    // </ChatButton>
    <>
      <ChatWidget
        accountId={idengagerchatConfig.accountId}
        title="Welcome to Idengager!"
        subtitle="Ask us anything in the chat window below 😊"
        newMessagePlaceholder="Start typing..."
        customer={{ external_id: user?.objectId,email: user?.email,name: user?.username, metadata:customerApps || apps[0] }}
        primaryColor="#0097c2"
        greeting="Hi there! How can I help you?"
        baseUrl={idengagerchatConfig.baseUrl}
      />
    </>
  );
};

export default SupportChat;
