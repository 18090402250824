import leftArrowIcon from './../icons/left-arrow.svg';
import leftBottomIcon from './../icons/bottom-arrow.svg';
import appIcon from './../icons/app-icon.svg';
import { Layout, Menu, Space, Dropdown } from 'antd';
import { Link, useLocation, useParams } from "react-router-dom";
import * as ROUTES from './../constants/routes';
import { useEffect, useState } from 'react';
import logoPlaceholder from './../assets/images/placeholder-logo.png';
import useGetAllApps from '../hooks/get_all_apps';
import { getActiveApp } from './../utils/storage';
import { reload_apps_interval, app_completed_status_key, app_in_progress_status_key } from '../constants/apps_constants';
import { withPrivate } from './PrivateRoute';

function TopNav(props) {
    const { Header } = Layout;
    const [activeApp, setActiveApp] = useState(null);
    const [appId, setAppId] = useState(null);
    let { id } = useParams();
    const location = useLocation();
    const { apps, setReLoadApps } = useGetAllApps();
    const [inprogressExist, setInprogressExist] = useState(false); // Flag for Inprogress check in apps list

    // Check If Apps List Have an App With (In Progress) Status & Handle Apps Refresh Every "reload_apps_interval" Minutes
    useEffect(() => {
        if (inprogressExist === false) {
            for (let i = 0; i < apps.length; i++) {
                if (apps[i].appStatus === app_in_progress_status_key) {
                    setInterval(() => {
                        setReLoadApps(true);
                    }, (1000 * 60) * reload_apps_interval);
                    setInprogressExist(true);
                    break
                }
            }
        }
    }, [apps]);

    useEffect(() => {
        if ((ROUTES.BASE_HOME + id) === location.pathname) {
            setAppId(id);
        }
        else {
            const app = getActiveApp();
            if (app) {
                setAppId(app.objectId);
            }
        }
    }, [id]);

    // Get Current Active Application
    useEffect(() => {
        if (appId && apps) {
            apps.forEach(app => {
                if (app.objectId == appId) {
                    setActiveApp(app);
                    return
                }
            });
        }
    }, [appId, apps]);

    const menu = (
        <Menu>
            {
                apps.map((app) => {
                    return (
                        <Menu.Item key={app.objectId} disabled={(!app.appStatus || (app.appStatus && app.appStatus !== app_completed_status_key)) ? true : false}>
                            <Link to={ROUTES.BASE_HOME + app.objectId}>{app.appName}</Link>
                        </Menu.Item>
                    )
                })
            }
        </Menu>
    );

    function toggle() {
        props.setCollapsed(!props.collapsed);
        document.getElementById("logo").classList.toggle('logo-trigger');
    };

    return (
        <Header className="navbar">
            <img className="trigger" id="side-menu-trigger" onClick={toggle} src={props.collapsed ? leftArrowIcon : leftArrowIcon} alt="trigger-icon" />
            <Space className="active-app" size={15}>
                <img className="app-icon" src={(activeApp && activeApp.appImage && activeApp.appImage !== "") ? activeApp.appImage : logoPlaceholder} alt="active-app-icon" />
                <Dropdown overlay={menu} trigger={['click']}>
                    <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {activeApp && activeApp.appName} <img src={leftBottomIcon} alt="left-bottom-arrow-icon" />
                    </span>
                </Dropdown>
                <img src={appIcon} alt="app-icon" />
            </Space>
        </Header>
    );
}

export default withPrivate(TopNav);