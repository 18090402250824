import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";

export const CLASS_NAME = "Asset";
export const INCLUDE = "";

export const FIELD_MAPPER = {
    objectId: " ID",
    externalurl: "Asset URL",
};

function BlockAssets() {
    return <BlogItem blogObjectName={CLASS_NAME} mapper={FIELD_MAPPER} />;
}

export default withPrivate(BlockAssets);
