import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllBranches() {
     /**
   * Fetches jobs
   * lines - maximum number of lines to fetch
   * since - only fetch lines since this Date
   */

    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [branches, setBranches] = useState([]);
    const [reloadBranches, setReloadBranches] = useState(true);

    useEffect(() => {
        if (data && data.results) {
            setBranches(data.results);
        }
            // console.job("data"+JSON.stringify([data[0]]));
    }, [data]);

    // useEffect(() => {
    //     if (loading === false) {
    //         setCatalogs(false);
    //     }
    // }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reloadBranches && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/classes/Branches' ;
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reloadBranches, setRoute, setHeaders]);

    return { branches, loading, setReloadBranches }
}

