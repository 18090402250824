import axios from "axios";
import React from "react";
import { getActiveApp } from "../utils/storage";
import useAsync from "./useAsync";

export const getBlogDeploy = async () => {
    const app = getActiveApp();
    const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/BlogDeploy"
    const headers = {
        "Idengager-Application-Id": app.backendAppName || app.applicationID,
        "Idengager-Master-Key": app.masterKey,
    };
    const response = await axios.get(url, { headers: headers });
    return response.data;
}

export function usePost(id){
    const async = useAsync();

    const getPost = async () => {
        const app = getActiveApp();
        const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/Post/" + id + "?include=authors,body,categories,excerpt,excerpt.children,body.children,slug,mainImage,authors.slug,authors.image,authors.bio,authors.bio.children,authors.country,authors.name,mainImage.crop,mainImage.hotspot,authors.image.crop,authors.image.hotspot";
        const headers = {
            "Idengager-Application-Id": app.backendAppName || app.applicationID,
            "Idengager-Master-Key": app.masterKey,
        };
        const response = await axios.get(url, { headers: headers });
        return response.data;
    }

    const revalidate = async () => {
        const post = await getPost(id);
        async.setData(post);
    }

    React.useEffect(
        () => {    
            async.run(getPost());
        },
        [id]
    );

    return { ...async, revalidate };
}

export function useCreatePostItem(){
    const { run, ...rest } = useAsync();

    const create = async (name, data) => {
        const app = getActiveApp();
        const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/" + name;
        const headers = {
            "Idengager-Application-Id": app.backendAppName || app.applicationID,
            "Idengager-Master-Key": app.masterKey,
        };
        const response = await run(axios.post(url, data, { headers: headers }));
        return response.data.objectId;
    }

    return {
        create,
        ...rest
    }
}

export function useUpdatePostItem(){
    const { run, ...rest } = useAsync();

    const update = async (name, itemId, data) => {
        const app = getActiveApp();
        const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/" + name + "/" + itemId;
        const headers = {
            "Idengager-Application-Id": app.backendAppName || app.applicationID,
            "Idengager-Master-Key": app.masterKey,
        };
        const response = await run(axios.put(url, data, { headers: headers }));
        return response.data;
    }

    return {
        update,
        ...rest
    }
}

export function useRemovePostItem(){
    const { run, ...rest } = useAsync();

    const remove = async (name, itemId) => {
        const app = getActiveApp();
        const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/" + name + "/" + itemId;
        const headers = {
            "Idengager-Application-Id": app.backendAppName || app.applicationID,
            "Idengager-Master-Key": app.masterKey,
        };
        const response = await run(axios.delete(url, { headers: headers }));
        return response.data;
    }

    return {
        remove,
        ...rest
    }
}