import axios from "axios";

const CURRENT_USER_KEY = "current_user";
const LOGIN_URL = process.env.REACT_APP_URL + "/idengager/login";

// Send auth header

function setAuthHeader(token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function removeAuthHeader() {
    delete axios.defaults.headers.common["Authorization"];
}

// Store Session Token

function setCurrentUser(user) {
    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));
}

function removeCurrentUser() {
    localStorage.removeItem(CURRENT_USER_KEY);
}

function getCurrentUser() {
    const user = localStorage.getItem(CURRENT_USER_KEY);

    if (!user) return null;

    return JSON.parse(user);
}

// Send login request

function handleLoginResponse({ data }, remembered) {
    if (remembered) {
        setCurrentUser(data);
    }

    //setAuthHeader(data.sessionToken);
    return data;
}

function login(data) {
    const config = {
        url: LOGIN_URL,
        method: "POST",
        data: { username: data.username, password: data.password },
        headers: {
            'Content-Type': 'application/json',
            "Idengager-Application-Id": process.env.REACT_APP_AUTH_APP_ID,
            "Idengager-Master-Key": process.env.REACT_APP_AUTH_MASTER_KEY
        }
    };

    return axios(config).then(res => handleLoginResponse(res, data.remembered));
}

function logout() {
    removeAuthHeader();
    removeCurrentUser();
}

export {
    login,
    logout,
    getCurrentUser,
    setCurrentUser,
    setAuthHeader
}