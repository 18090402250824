import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from './RealEstateItem';
import { 
    CLASS_NAME as UNIT_CLASS_NAME, 
    FIELD_MAPPER as UNIT_FIELD_MAPPER, 
    transformUnitFormValues, 
    unitFormFields 
} from "./Units";

export const CLASS_NAME = "Brand";

export const FIELD_MAPPER = {
    "Make_c": "Project Name EN",
    "MakeArabic_c": "Project Name Ar",
    "Make_Id": "Project ID",
    "Brand_c": "Stage Name EN",
    "BrandArabic_c": "Stage Name AR",
    "PDF_c": "Stage Image",
    "CurrencyCode": "UOM",
    "RecordName": "Stage Title",
    "Year_c": "Year"
}

export const formFields = (function(){
    const arr = [
        "Make_Id",
        "Make_c",
        "MakeArabic_c",
        "Brand_c",
        "BrandArabic_c",
        "PDF_c",
        "CurrencyCode",
        "RecordName"
    ].map(name => {
        return {
            name,
            label: FIELD_MAPPER[name],
            type: "text"
        }
    });

    arr.push({
        name: "Price_c", 
        label: "Price",
        type: "number"
    });

    return arr;
})()
export function transformStageFormValues(values){
    const data = { ...values };
    data.Year_c = 2022;
    return data;
}

function ProjectStages(){
    return (
        <RealEstateItem 
            realEstateObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            formFields={formFields}
            transform={transformStageFormValues}
            extraCells={[
                {
                    Header: "Add Unit",
                    accessor: "MakeArabic_c",
                    Cell: (_, stage) => {
                        return (
                            <RealEstateItem 
                                mapper={UNIT_FIELD_MAPPER}
                                realEstateObjectName={UNIT_CLASS_NAME}
                                formFields={unitFormFields}
                                transform={transformUnitFormValues}
                                formClassName="col-2"
                                form={{
                                    btn: "Add Unit",
                                    btnId: "add-unit-btn",
                                    disabled:["Make_Id", "Make_c", "MakeArabic_c", "Brand_c", "BrandArabic_c", "Brand_Id"],
                                    initialValues: {
                                        Make_Id: stage.Make_Id,
                                        Make_c: stage.Make_c,
                                        MakeArabic_c: stage.MakeArabic_c,
                                        Brand_Id: stage.objectId,
                                        Brand_c: stage.Brand_c,
                                        BrandArabic_c: stage.BrandArabic_c
                                    }
                                }}
                            />
                        )
                    }
                }
            ]}
        />
    )
}

export default withPrivate(ProjectStages);