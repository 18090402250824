import { Button } from "antd";
import useAsync from "../hooks/useAsync";
import axios from "axios";
import { getActiveApp } from "../utils/storage";
import { useRef } from "react";

function UploadImage({ image, onUpload }) {
    const { run, isLoading } = useAsync();
    const ref = useRef();

    const upload = async (file) => {
        const url =
            `https://mivorsproxyapi1.herokuapp.com/cors?url=https://idengager-zbgieg5y0l.herokuapp.com/idengager/files/${file.name}`;

        const headers = {
            "Content-Type": file.type,
            "Idengager-Application-Id": "idengager-zbgieg5y0l",
            "Idengager-Master-Key": "idengager-qp2hnd0frx",
        };

        const formData = new FormData();
        formData.append("file", file);

        const res = await axios.post(url, file, { headers });
        onUpload(res.data);
        onUpload(res.data);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        run(upload(file));
    };

    return (
        <div>
            <Button
                loading={isLoading}
                disabled={isLoading}
                onClick={() => ref.current.click()}
            >
                Upload Image
            </Button>

            <input
                type="file"
                id="image-file"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={ref}
                key={Date.now()}
            />

            <div>{image ? <img src={image} /> : null}</div>
        </div>
    );
}

export default UploadImage;
