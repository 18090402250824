import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";
import {
    CLASS_NAME as BLOCK_CLASS_NAME,
    FIELD_MAPPER as BLOCK_FIELD_MAPPER,
    transformBlockFormValues,
    blockFormFields,
} from "./Blocks";

export const CLASS_NAME = "Author";
export const INCLUDE = "";

export const FIELD_MAPPER = {
    name: "Author Name",
    objectId: "Author Id",
    bio: "Author Bio Blocks",
    country: "Author country",
    image: "Author image",
    slug: "slug",
};

export const formFields = (function () {
    const arr = ["name", "bio", "country", "image"].map((name) => {
        return {
            name,
            label: FIELD_MAPPER[name],
            type: "text",
        };
    });
    return arr;
})();

export const transformAuthorFormValues = (values) => {
    const data = { ...values };
    data.slug = {
        objectId: data.slug,
        __type: "Pointer",
        className: "Slug",
    };
    data.image = {
        objectId: data.image,
        __type: "Pointer",
        className: "Asset",
    };
    data.bio = [
        {
            objectId: data.bio,
            __type: "Pointer",
            className: "Block",
        },
    ];
    return data;
};

function PostAuthors() {
    return (
        <BlogItem
            blogObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            formFields={formFields}
            transform={transformAuthorFormValues}
            extraCells={[
                {
                    Header: "Add to Post",
                    accessor: "MakeArabic_c",
                    Cell: (_, author) => {
                        return (
                            <BlogItem
                                mapper={BLOCK_FIELD_MAPPER}
                                blogObjectName={BLOCK_CLASS_NAME}
                                formFields={blockFormFields}
                                transform={transformBlockFormValues}
                                formClassName="col-2"
                                form={{
                                    btn: "Add Bio Block",
                                    disabled: [
                                        "objectId",
                                        "name",
                                        "MakeArabic_c",
                                        "bio",
                                        "BrandArabic_c",
                                        "Brand_Id",
                                    ],
                                    initialValues: {
                                        name: author.name,
                                        MakeArabic_c: author.MakeArabic_c,
                                        Brand_Id: author.objectId,
                                        bio: author.bio,
                                        BrandArabic_c: author.BrandArabic_c,
                                    },
                                }}
                            />
                        );
                    },
                },
            ]}
        />
    );
}

export default withPrivate(PostAuthors);
