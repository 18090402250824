import React from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
    Button,
    Form,
    Input,
    Space,
    notification,
    InputNumber,
    Select,
} from "antd";
import { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons";
import { reducer, initialPost, getDraftStateFromHTML } from "./helpers";
import {
    usePost,
    useCreatePostItem,
    useUpdatePostItem,
} from "../../hooks/post";
import axios from "axios";
import UploadImage from "../../components/UploadImage";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import useDeployBlog from "../../hooks/deploy_blog";

const PostContext = React.createContext();

const spanMarksOptions = [
    {
        value: "link",
        label: "Link",
    },
    {
        value: "strong",
        label: "Strong",
    },
    {
        value: "emphasis",
        label: "Emphasis",
    },
    {
        value: "orderedList",
        label: "Ordered List",
    },
    {
        value: "unOrderedList",
        label: "Unordered List",
    },
];

function ImageCrop({ crop = {}, onChange }) {
    return (
        <div>
            <h4 style={{ margin: "0 0 12px" }}>Image Crop</h4>
            <Form
                layout="inline"
                initialValues={crop || {}}
                onValuesChange={(_, values) => {
                    let objectId = crop.objectId || Date.now();
                    onChange({ ...values, objectId });
                }}
            >
                <Form.Item name="top" label="Top">
                    <InputNumber />
                </Form.Item>
                <Form.Item name="bottom" label="Bottom">
                    <InputNumber />
                </Form.Item>
                <Form.Item name="left" label="Left">
                    <InputNumber />
                </Form.Item>
                <Form.Item name="right" label="Right">
                    <InputNumber />
                </Form.Item>
            </Form>
        </div>
    );
}

function ImageHotspot({ hotspot = {}, onChange }) {
    return (
        <div>
            <h4 style={{ margin: "0 0 12px" }}>Image Hotspot</h4>
            <Form
                layout="inline"
                initialValues={hotspot || {}}
                onValuesChange={(_, values) => {
                    let objectId = hotspot.objectId || Date.now();
                    onChange({ ...values, objectId });
                }}
            >
                <Form.Item name="height" label="Height">
                    <InputNumber />
                </Form.Item>
                <Form.Item name="width" label="Width">
                    <InputNumber />
                </Form.Item>
                <Form.Item name="x" label="X">
                    <InputNumber />
                </Form.Item>
                <Form.Item name="y" label="Y">
                    <InputNumber />
                </Form.Item>
            </Form>
        </div>
    );
}

function BlockSpans({ block, authorId, excerptId }) {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div className="Post__detail-header">
                <Space>
                    <h3>{!authorId ? "Block" : "Author Bio"}</h3>

                    <Button
                        onClick={() =>
                            dispatch({
                                type:
                                    !authorId && !excerptId
                                        ? "add_span"
                                        : authorId
                                        ? "add_author_bio_span"
                                        : "add_excerpt_span",
                                blockId: block.objectId,
                                authorId,
                            })
                        }
                        size="small"
                    >
                        + span
                    </Button>

                    <Button
                        icon={<DeleteFilled />}
                        type="text"
                        size="small"
                        onClick={() =>
                            dispatch({
                                type:
                                    !authorId && !excerptId
                                        ? "remove_block"
                                        : authorId
                                        ? "remove_author_bio_block"
                                        : "remove_excerpt_block",
                                blockId: block.objectId,
                                authorId: authorId,
                            })
                        }
                        danger
                    />
                </Space>
            </div>

            <div className="grid-col-2">
                {block.children?.map((span, index) => {
                    return (
                        <div>
                            <Form
                                layout="vertical"
                                style={{ margin: "10px 0",  position: "relative" }}
                                name={`spanForm-${span.objectId}`}
                                size="small"
                                onValuesChange={(data) =>
                                    dispatch({
                                        type:
                                            !authorId && !excerptId
                                                ? "update_span"
                                                : authorId
                                                ? "update_author_bio_span"
                                                : "update_excerpt_span",
                                        blockId: block.objectId,
                                        spanId: span.objectId,
                                        data: {
                                            ...data,
                                            marks: data.marks
                                                ? [data.marks]
                                                : null,
                                        },
                                        authorId,
                                    })
                                }
                                initialValues={{
                                    text: span.text,
                                    marks: span.marks?.[0],
                                }}
                            >
                                <Form.Item
                                    name="text"
                                    label={
                                        <div>
                                            <Space>
                                                Span
                                                <Button
                                                    icon={<DeleteFilled />}
                                                    type="text"
                                                    size="small"
                                                    onClick={() =>
                                                        dispatch({
                                                            type:
                                                                !authorId &&
                                                                !excerptId
                                                                    ? "remove_span"
                                                                    : authorId
                                                                    ? "remove_author_bio_span"
                                                                    : "remove_excerpt_span",
                                                            blockId:
                                                                block.objectId,
                                                            spanId: span.objectId,
                                                            authorId,
                                                        })
                                                    }
                                                    danger
                                                />
                                            </Space>
                                        </div>
                                    }
                                >
                                    <Input.TextArea
                                        style={{ minWidth: "400px" }}
                                    />
                                </Form.Item>

                                <Form.Item name="marks" style={{ position: "absolute", right: "10px", top: "0px" }}>
                                    <Select
                                        options={spanMarksOptions}
                                        placeholder="Span mark"
                                        style={{ minWidth: "120px" }}
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function ExcerptBlocks() {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div className="Post__detail-header">
                <h2>Excerpts</h2>
                <div>
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => dispatch({ type: "add_excerpt_block" })}
                    >
                        Add
                    </Button>
                </div>
            </div>

            <div className="Post__detail">
                {post.excerpt.map((block, index) => {
                    return (
                        <div className="Post__sub-detail">
                            <BlockSpans block={block} excerptId={true} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function PostBlocks() {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div className="Post__detail-header">
                <h2>Body</h2>
                <div>
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => dispatch({ type: "add_block" })}
                    >
                        Add
                    </Button>
                </div>
            </div>

            <div className="Post__detail">
                {post.body.map((block, index) => {
                    return (
                        <div className="Post__sub-detail">
                            <BlockSpans block={block} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function PostContent() {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div style={{ margin: "10px 0" }}>
            <div className="Post__detail-header">
                <h2>Body</h2>
            </div>

            <div
                style={{
                    border: "1px solid gray",
                    padding: "10px",
                    borderRadius: "12px",
                }}
            >
                <Editor
                    defaultEditorState={post.content}
                    onEditorStateChange={(state) =>
                        dispatch({ type: "set_content", content: state })
                    }
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                />
            </div>
        </div>
    );
}

function AuthorBlocks({ author }) {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div className="Post__detail-header">
                <h2>Bio</h2>
                <div>
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() =>
                            dispatch({
                                type: "add_author_bio_block",
                                authorId: author.objectId,
                            })
                        }
                    >
                        Add
                    </Button>
                </div>
            </div>

            <div className="Post__detail">
                {author.bio?.map((block, index) => {
                    return (
                        <div>
                            <BlockSpans
                                block={block}
                                authorId={author.objectId}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function AuthorImage({ author }) {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div>
                <Form
                    layout="vertical"
                    name="Slug Form"
                    className="grid-col-2"
                    onValuesChange={(_, data) => {
                        data.idengagerfile.__type = "File";
                        dispatch({
                            type: "update_author",
                            authorId: author.objectId,
                            data: {
                                image: {
                                    ...author.image,
                                    ...data,
                                },
                            },
                        });
                    }}
                    initialValues={{
                        alt: author.image?.alt,
                        externalurl: author.image?.externalurl,
                        idengagerfile: {
                            ...(author.image?.idengagerfile || {}),
                            __type: "File",
                        },
                    }}
                >
                    <Form.Item name="alt" label="Author Image Description">
                        <Input />
                    </Form.Item>

                    <Form.Item name="externalurl" label="Author Image Url">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={["idengagerfile", "name"]}
                        label="Image Idengager file name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={["idengagerfile", "url"]}
                        label="Image Idengager file url"
                    >
                        <Input />
                    </Form.Item>
                </Form>

                <div style={{ margin: "18px 0" }}>
                    <ImageCrop
                        crop={author.image?.crop}
                        onChange={(crop) => {
                            dispatch({
                                type: "update_author",
                                authorId: author.objectId,
                                data: {
                                    image: {
                                        ...author.image,
                                        crop,
                                    },
                                },
                            });
                        }}
                    />
                </div>

                <div style={{ margin: "18px 0" }}>
                    <ImageHotspot
                        hotspot={author.image?.hotspot}
                        onChange={(hotspot) => {
                            dispatch({
                                type: "update_author",
                                authorId: author.objectId,
                                data: {
                                    image: {
                                        ...author.image,
                                        hotspot,
                                    },
                                },
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

function Categories() {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div className="Post__detail-header">
                <h2>Categories</h2>
                <div>
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => dispatch({ type: "add_category" })}
                    >
                        Add
                    </Button>
                </div>
            </div>

            {post.categories.map((category, index) => {
                return (
                    <div className="Post__sub-detail">
                        <Space>
                            <h3>Category {index + 1}</h3>
                            <Button
                                icon={<DeleteFilled />}
                                type="text"
                                onClick={() =>
                                    dispatch({
                                        type: "remove_category",
                                        categoryId: category.objectId,
                                    })
                                }
                                danger
                            />
                        </Space>

                        <Form
                            className="grid-col-2"
                            layout="vertical"
                            name={`categoryForm-${category.objectId}`}
                            onValuesChange={(data) =>
                                dispatch({
                                    type: "update_category",
                                    categoryId: category.objectId,
                                    data,
                                })
                            }
                            initialValues={{
                                title: category.title,
                                description: category.description,
                            }}
                        >
                            <Form.Item name="title" label="Title">
                                <Input />
                            </Form.Item>

                            <Form.Item name="description" label="Description">
                                <Input />
                            </Form.Item>
                        </Form>
                    </div>
                );
            })}
        </div>
    );
}

function Authors() {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div className="Post__detail-header">
                <h2>Authors</h2>
                <div>
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => dispatch({ type: "add_author" })}
                    >
                        Add
                    </Button>
                </div>
            </div>

            <div>
                {post.authors.map((author, index) => {
                    return (
                        <div className="Post__sub-detail">
                            <Space style={{ margin: "0 0 24px" }}>
                                <h3 style={{ margin: "0", fontSize: "18px" }}>
                                    Author {index + 1}
                                </h3>
                                <Button
                                    icon={<DeleteFilled />}
                                    type="text"
                                    size="small"
                                    onClick={() =>
                                        dispatch({
                                            type: "remove_author",
                                            authorId: author.objectId,
                                        })
                                    }
                                    danger
                                />
                            </Space>

                            <Form
                                className="grid-col-2"
                                layout="vertical"
                                style={{ margin: "10px 0" }}
                                name={`authorForm-${author.objectId}`}
                                onValuesChange={(data) =>
                                    dispatch({
                                        type: "update_author",
                                        authorId: author.objectId,
                                        data,
                                    })
                                }
                                initialValues={{
                                    name: author.name,
                                    country: author.country,
                                }}
                            >
                                <Form.Item name="name" label="Author Name">
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="country"
                                    label="Author Country"
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                            <AuthorImage author={author} />
                            <AuthorBlocks author={author} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function PostImage() {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div className="Post__detail-header">
                <h2 style={{ margin: "18px 0" }}>Post Image</h2>
            </div>

            <div>
                <Form
                    className="grid-col-2"
                    layout="vertical"
                    name="mainImageForm"
                    onValuesChange={(_, data) => {
                        data.idengagerfile.__type = "File";
                        dispatch({ type: "set_main_image", data });
                    }}
                    key={post.mainImage?.externalurl}
                    initialValues={{
                        alt: post.mainImage?.alt,
                        externalurl: post.mainImage?.externalurl,
                        idengagerfile: {
                            ...(post.mainImage?.idengagerfile || {}),
                            __type: "File",
                        },
                    }}
                >
                    <Form.Item name="alt" label="Post Image ALT">
                        <Input />
                    </Form.Item>

                    <Form.Item name="externalurl" label="Post Image URL">
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={["idengagerfile", "name"]}
                        label="Image Idengager file name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={["idengagerfile", "url"]}
                        label="Image Idengager file url"
                    >
                        <Input />
                    </Form.Item>
                </Form>

                <div style={{ marginTop: "24px" }}>
                    <UploadImage
                        image={post.mainImage?.externalurl}
                        onUpload={({ url, name }) => {
                            dispatch({
                                type: "set_main_image",
                                data: {
                                    externalurl: url,
                                    idengagerfile: {
                                        __type: "File",
                                        name,
                                        url,
                                    },
                                },
                            });
                        }}
                    />
                </div>

                <div style={{ margin: "18px 0" }}>
                    <ImageCrop
                        crop={post.mainImage?.crop}
                        onChange={(crop) => {
                            console.log(crop);
                            dispatch({
                                type: "set_main_image",
                                data: { crop },
                            });
                        }}
                    />
                </div>

                <div style={{ margin: "18px 0" }}>
                    <ImageHotspot
                        hotspot={post.mainImage?.hotspot}
                        onChange={(hotspot) => {
                            dispatch({
                                type: "set_main_image",
                                data: { hotspot },
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

function Slug() {
    const [post, dispatch] = React.useContext(PostContext);

    return (
        <div>
            <div>
                <Form
                    layout="vertical"
                    name="Slug Form"
                    onValuesChange={(values) => {
                        dispatch({ type: "set_slug", data: values });
                    }}
                    initialValues={{
                        current: post.slug.current,
                    }}
                >
                    <Form.Item name="current" label="Slug">
                        <Input />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

function Post() {
    const [post, dispatch, initialPost] = React.useContext(PostContext);
    const { create } = useCreatePostItem();
    const { update } = useUpdatePostItem();
    const [isLoading, setIsLoading] = React.useState(false);
    // const [isDeploying, setIsDeploying] = React.useState(false);
    const { deploy, isLoading: isDeploying } = useDeployBlog();

    const handlePublish = async () => {
        setIsLoading(true);
        const publishedAt = new Date().toISOString();
        const authors = await saveAuthors(post.authors);
        const body = await saveBlocks(post.body);
        const excerpt = await saveBlocks(post.excerpt);
        const slug = await saveSlug(post.slug);
        const mainImage = await saveImage(post.mainImage);
        const categories = await saveCategories(post.categories);
        const content = draftToHtml(
            convertToRaw(post.content.getCurrentContent())
        );

        update("Post", post.objectId, {
            authors,
            body,
            slug,
            mainImage,
            categories,
            excerpt,
            title: post.title,
            publishedAt,
            content,
        }).then((res) => {
            notification.success({ message: "Post published successfully" });
            setIsLoading(false);
        });
    };

    const handleDeploy = () => {
        deploy();
    };

    const saveAuthors = async (authors) => {
        let savedAuthors = [];
        for (let author of authors) {
            let authorId = author.objectId;

            const authorImage = await saveAuthorImage(author.image);
            const bio = await saveBlocks(author.bio);

            if (typeof authorId === "number") {
                authorId = await create("Author", {
                    name: author.name,
                    country: author.country,
                    bio,
                    image: authorImage,
                });
            } else {
                update("Author", authorId, {
                    name: author.name,
                    country: author.country,
                    bio,
                    image: authorImage,
                });
            }

            savedAuthors.push({
                __type: "Pointer",
                className: "Author",
                objectId: authorId,
            });
        }
        return savedAuthors;
    };

    const saveAuthorImage = async (authorImage) => {
        let objectId = authorImage.objectId;
        const crop = await saveCrop(authorImage.crop || {});
        const hotspot = await saveHotspot(authorImage.hotspot || {});

        if (typeof objectId === "number" || !objectId) {
            objectId = await create("AuthorImage", {
                alt: authorImage.alt,
                externalurl: authorImage.externalurl,
                idengagerfile: authorImage.idengagerfile,
                crop,
                hotspot,
            });
        } else {
            update("AuthorImage", objectId, {
                objectId: authorImage.objectId,
                externalurl: authorImage.externalurl,
                idengagerfile: authorImage.idengagerfile,
                alt: authorImage.alt,
                crop,
                hotspot,
            });
        }

        return {
            __type: "Pointer",
            className: "AuthorImage",
            objectId,
            crop,
            hotspot,
        };
    };

    const saveImage = async (mainImage) => {
        let objectId = mainImage.objectId;
        const crop = await saveCrop(mainImage.crop || {});
        const hotspot = await saveHotspot(mainImage.hotspot || {});

        if (typeof objectId === "number") {
            objectId = await create("MainImage", {
                alt: mainImage.alt,
                externalurl: mainImage.externalurl,
                idengagerfile: mainImage.idengagerfile,
                crop,
                hotspot,
            });
        } else {
            update("MainImage", mainImage.objectId, {
                objectId: mainImage.objectId,
                externalurl: mainImage.externalurl,
                idengagerfile: mainImage.idengagerfile,
                alt: mainImage.alt,
                crop,
                hotspot,
            });
        }

        return {
            objectId,
            className: "MainImage",
            __type: "Pointer",
            crop,
            hotspot,
        };
    };

    const saveSlug = async (slug) => {
        let objectId = slug.objectId;

        if (typeof slug.objectId === "number") {
            objectId = await create("Slug", {
                current: slug.current,
            });
        } else {
            update("Slug", slug.objectId, {
                objectId: slug.objectId,
                current: slug.current,
            });
        }

        return {
            objectId,
            className: "Slug",
            __type: "Pointer",
        };
    };

    const saveBlocks = async (blocks = []) => {
        let savedBlocks = [];
        for (let block of blocks) {
            savedBlocks.push(await saveBlock(block));
        }
        return savedBlocks;
    };

    const saveBlock = async (block) => {
        let spans = [];

        let blockId = block.objectId;

        for (let span of block.children) {
            spans.push(await saveSpan(span));
        }

        if (typeof blockId === "number") {
            blockId = await create("Block", {
                style: "normal",
                children: spans,
            });
        } else {
            update("Block", blockId, {
                objectId: blockId,
                style: "normal",
                children: spans,
            });
        }

        console.log(spans);
        console.log(blockId);

        return {
            objectId: blockId,
            className: "Block",
            __type: "Pointer",
        };
    };

    const saveSpan = async (span) => {
        let spanId = span.objectId;

        if (typeof spanId === "number") {
            spanId = await create("Span", {
                text: span.text,
                marks: span.marks,
            });
        } else {
            await update("Span", span.objectId, {
                objectId: span.objectId,
                text: span.text,
                marks: span.marks,
            });
        }

        console.log(spanId);

        return {
            objectId: spanId,
            __type: "Pointer",
            className: "Span",
        };
    };

    const saveCategories = async (categories) => {
        const savedCategories = [];

        for (let category of categories) {
            let categoryId = category.objectId;

            if (typeof categoryId === "number" || !categoryId) {
                categoryId = await create("Category", {
                    title: category.title,
                    description: category.description,
                });
            } else {
                update("Category", category.objectId, {
                    title: category.title,
                    description: category.description,
                    objectId: category.objectId,
                });
            }

            savedCategories.push({
                objectId: categoryId,
                className: "Category",
                __type: "Pointer",
            });
        }

        return savedCategories;
    };

    const saveCrop = async (crop) => {
        let objectId = crop.objectId;

        const { objectId: _id, createdAt, updatedAt, __type, ...values } = crop;

        if (typeof crop.objectId === "number") {
            objectId = await create("ImageCrop", values);
        } else {
            update("ImageCrop", crop.objectId, {
                objectId: crop.objectId,
                ...values,
            });
        }

        return {
            objectId,
            className: "ImageCrop",
            __type: "Pointer",
        };
    };

    const saveHotspot = async (hotspot) => {
        let objectId = hotspot.objectId;

        const {
            objectId: _id,
            createdAt,
            updatedAt,
            __type,
            ...values
        } = hotspot;

        if (typeof hotspot.objectId === "number") {
            objectId = await create("ImageHotspot", values);
        } else {
            update("ImageHotspot", hotspot.objectId, {
                objectId: hotspot.objectId,
                ...values,
            });
        }

        return {
            objectId,
            className: "ImageHotspot",
            __type: "Pointer",
        };
    };

    return (
        <div className="Post__container">
            <div className="Post__publish">
                <Button
                    type="dashed"
                    size="large"
                    loading={isDeploying}
                    disabled={isDeploying}
                    onClick={handleDeploy}
                    style={{ marginRight: "16px" }}
                >
                    Deploy
                </Button>

                <Button
                    type="primary"
                    size="large"
                    loading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                        handlePublish();
                    }}
                >
                    Publish
                </Button>
            </div>
            <div className="Post__wrapper">
                <h2>Post</h2>

                <div className="Post__metadata">
                    <div>
                        Created At:{" "}
                        {moment(post.createdAt).format("DD/MM/YYYY")}
                    </div>
                    <div>
                        Updated At:{" "}
                        {moment(post.updatedAt).format("DD/MM/YYYY")}
                    </div>
                </div>

                <div className="grid-col-2">
                    <div>
                        <Form
                            onValuesChange={(values) =>
                                dispatch({
                                    type: "set_title",
                                    title: values.title,
                                })
                            }
                            name="postForm"
                            layout="vertical"
                            initialValues={{
                                title: post.title,
                            }}
                        >
                            <Form.Item label="Title" name="title">
                                <Input />
                            </Form.Item>
                        </Form>
                    </div>
                    <Slug />
                </div>
                <PostImage />
                <Authors />
                <Categories />
                <PostBlocks />
                {/* <PostContent /> */}
                <ExcerptBlocks />
            </div>
        </div>
    );
}

function PostProvider({ children, initialPost }) {
    const [post, dispatch] = React.useReducer(reducer, initialPost);

    return (
        <PostContext.Provider value={[post, dispatch, initialPost]}>
            {children}
        </PostContext.Provider>
    );
}

export default () => {
    const { id: postId } = useParams();
    const { data: post, isLoading } = usePost(postId);

    if (isLoading) return null;

    return (
        <PostProvider
            initialPost={{
                ...initialPost,
                ...post,
                content: getDraftStateFromHTML(post?.content || ""),
            }}
        >
            <Post />
        </PostProvider>
    );
};
