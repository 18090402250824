import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";
import {
    CLASS_NAME as BLOCK_CLASS_NAME,
    FIELD_MAPPER as BLOCK_FIELD_MAPPER,
    transformBlockFormValues,
    blockFormFields,
} from "./Blocks";

export const CLASS_NAME = "Slug";
export const INCLUDE = "";

export const FIELD_MAPPER = {
    current: "current",
    objectId: "objectId",
};

export const formFields = (function () {
    const arr = ["current", "objectId"].map((name) => {
        return {
            name,
            label: FIELD_MAPPER[name],
            type: "text",
        };
    });

    return arr;
})();
export function transformSlugFormValues(values) {
    const data = { ...values };
    return data;
}

function PostSlugs() {
    return (
        <BlogItem
            blogObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            formFields={formFields}
            transform={transformSlugFormValues}
            extraCells={[
                {
                    Header: "Add Block",
                    accessor: "objectId",
                    Cell: (_, slug) => {
                        return (
                            <BlogItem
                                mapper={BLOCK_FIELD_MAPPER}
                                blogObjectName={BLOCK_CLASS_NAME}
                                formFields={blockFormFields}
                                transform={transformBlockFormValues}
                                formClassName="col-2"
                                form={{
                                    btn: "Add Block",
                                    disabled: ["objectId"],
                                    initialValues: {
                                        Make_Id: slug.Make_Id,
                                        Make_c: slug.Make_c,
                                        MakeArabic_c: slug.MakeArabic_c,
                                        Brand_Id: slug.objectId,
                                        Brand_c: slug.Brand_c,
                                        BrandArabic_c: slug.BrandArabic_c,
                                    },
                                }}
                            />
                        );
                    },
                },
            ]}
        />
    );
}

export default withPrivate(PostSlugs);
