import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from './RealEstateItem';
import { CLASS_NAME as FEATURES_CLASS_NAME, FIELD_MAPPER as FEATURES_FIELD_MAPPER } from "./UnitFeatures";
import { CLASS_NAME as OPTIONS_CLASS_NAME, FIELD_MAPPER as OPTIONS_FIELD_MAPPER } from "./UnitOptions";
import { CLASS_NAME as GALLERY_CLASS_NAME, FIELD_MAPPER as GALLERY_FIELD_MAPPER } from "./UnitGallery";
import { CLASS_NAME as PLANS_CLASS_NAME, FIELD_MAPPER as PLANS_FIELD_MAPPER, transformPaymentPlansFormValues } from "./PaymentPlans";

export const CLASS_NAME = "Catalogs";

export const FIELD_MAPPER = {
    "Make_c": "Project Name EN",
    "MakeArabic_c": "Project Name AR",
    "Make_Id": "Project ID",
    "Brand_Id": "Stage Id",
    "Brand_c": "Stage Name EN",
    "BrandArabic_c": "Stage Name AR",
    "RecordName": "Unit Name",
    "Image_c": "Unit Image",
    "Options_c": "Unit Description EN",
    "Arabic_Options_c": "Unit Description AR",
    "Available_c": "Available",
    "CurrencyCode": "UOM",
    "Price_c": "Unit Value (per UOM)",
    "Options_Short_c": "Unit short description EN",
    "Options_ShortAR_c": "Unit short description AR",
    "location": "Location"
}

export const unitFormFields = (function(){
    let arr = [
        ["Make_c", "text"],
        ["MakeArabic_c", "text"],
        ["Make_Id", "text"],
        ["Brand_Id", "text"],
        ["Brand_c", "text"],
        ["BrandArabic_c", "text"],
        ["RecordName", "text"],
        ["Image_c", "text"],
        ["Options_c", "textarea"],
        ["Arabic_Options_c", "textarea"],
        ["CurrencyCode", "text"],
        ["Price_c", "number"],
        ["Options_Short_c", "text"],
        ["Options_ShortAR_c", "text"],
    ].map(([name, type]) => {
        return {
            name,
            label: FIELD_MAPPER[name],
            type
        }
    });
    
    arr = arr.concat([
        {
            name: ["location", "latitude"],
            label: "Latitude",
            type: "number"
        },
        {
            name: ["location", "longitude"],
            label: "Longitude",
            type: "number"
        },
        {
            name: "Available_c",
            label: "Available",
            type: "boolean"
        }
    ]);

    return arr;
})();

export const transformUnitFormValues = values => {
    const data = { ...values };

    console.log(values);

    if(values.location && values.location.longitude && values.location.latitude) {
        data.location = {
            __type: "GeoPoint",
            ...values.location
        };
    }

    data.Available_c = data.Available_c ? 1 : 0;

    delete data.latitude;
    delete data.longitude;

    return data;
}

function Units(){
    return (
        <RealEstateItem 
            realEstateObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            formFields={unitFormFields}
            transform={transformUnitFormValues}
            formClassName="col-2"
            extraCells={[
                {
                    Header: "Add Feature",
                    accessor: "Make_c",
                    Cell: (_, unit) => {
                        return (
                            <RealEstateItem
                                mapper={FEATURES_FIELD_MAPPER}
                                realEstateObjectName={FEATURES_CLASS_NAME}
                                form={{
                                    btn: "Add Feature",
                                    initialValues: {
                                        Catalog_Id: unit.objectId
                                    }
                                }}
                            />
                        )
                    }
                },
                {
                    Header: "Add Option",
                    accessor: "Make_c",
                    Cell: (_, unit) => {
                        return (
                            <RealEstateItem
                                mapper={OPTIONS_FIELD_MAPPER}
                                realEstateObjectName={OPTIONS_CLASS_NAME}
                                form={{
                                    btn: "Add Option",
                                    initialValues: {
                                        Catalog_Id: unit.objectId
                                    }
                                }}
                            />
                        )
                    }
                },
                {
                    Header: "Add Gallery",
                    accessor: "Make_c",
                    Cell: (_, unit) => {
                        return (
                            <RealEstateItem
                                mapper={GALLERY_FIELD_MAPPER}
                                realEstateObjectName={GALLERY_CLASS_NAME}
                                form={{
                                    btn: "Add Gallery",
                                    initialValues: {
                                        Catalog_Id: unit.objectId
                                    }
                                }}
                            />
                        )
                    }
                },
                {
                    Header: "Add Payment Plan",
                    accessor: "Make_c",
                    Cell: (_, unit) => {
                        return (
                            <RealEstateItem 
                                mapper={PLANS_FIELD_MAPPER}
                                realEstateObjectName={PLANS_CLASS_NAME}
                                transform={transformPaymentPlansFormValues}
                                form={{
                                    btn: "Add Payment Plan",
                                    disabled: ["propertyId"],
                                    initialValues: {
                                        propertyId: unit.objectId
                                    }
                                }}
                            />
                        )
                    }
                }
            ]}
        />
    )
}

export default withPrivate(Units);