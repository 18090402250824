import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";

export const CLASS_NAME = "Category";
export const INCLUDE = "";

export const FIELD_MAPPER = {
    objectId: " ID",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    description: "description",
    title: "title",
};

export const formFields = (function () {
    const arr = [
        "objectId",
        "createdAt",
        "description",
        "updatedAt",
        "description",
        "title",
    ].map((name) => {
        return {
            name,
            label: FIELD_MAPPER[name],
            type: "text",
        };
    });

    return arr;
})();

export const columnRenderer = {
    catalog: (catalog) => catalog.objectId,
};

export const transformCategoriesFormValues = (values) => {
    const data = { ...values };
    data.catalog = {
        objectId: data.catalog,
        __type: "Pointer",
        className: "Catalogs",
    };
    data.active = true;
    data.default = true;
    return data;
};

function Categories() {
    return (
        <BlogItem
            blogObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            columnRenderer={columnRenderer}
            transform={transformCategoriesFormValues}
        />
    );
}

export default withPrivate(Categories);
