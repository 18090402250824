import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";

export const CLASS_NAME = "Span";
export const INCLUDE = "marks";

export const FIELD_MAPPER = {
    text: "Span Text",
    objectId: "objectId",
    marks: "Span Marks",
};

export const blockSpanFormFields = (function () {
    let arr = [
        ["text", "text"],
        ["objectId", "text"],
    ].map(([name, type]) => {
        return {
            name,
            label: FIELD_MAPPER[name],
            type,
        };
    });

    return arr;
})();

export const transformBlockSpanFormValues = (values) => {
    const data = { ...values };

    data.marks = [];

    return data;
};

function BlockSpans() {
    return (
        <BlogItem
            blogObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            formFields={blockSpanFormFields}
            transform={transformBlockSpanFormValues}
        />
    );
}

export default withPrivate(BlockSpans);
