import { withPrivate } from "../../components/PrivateRoute";
import RealEstateItem from "./RealEstateItem";
import {
    FIELD_MAPPER as STAGE_MAPPER,
    CLASS_NAME as STAGE_CLASS_NAME,
    formFields as stageFormFields,
    transformStageFormValues,
} from "./ProjectStages";
import { Button, notification } from "antd";
import { useState } from "react";
import axios from "axios";
const CLASS_NAME = "Make";

const FIELD_MAPPER = {
    PDF_c: "Project Image",
    MakeArabic_c: "Project Name AR",
    RecordName: "Project Name EN",
};

function Projects() {
    const [isDeploying, setIsDeploying] = useState(false);

    const handleDeploy = async () => {
        setIsDeploying(true);
        await axios.post("https://api.netlify.com/build_hooks/63ac3219fd55386e97895afd", {});
        notification.success({ message: "Deployed Successfully" })
        setIsDeploying(false);
    }

    return (
        <>
            <div style={{ margin: "100px 20px 0px", display: "flex", justifyContent: "flex-end" }}>
                <Button type="primary" loading={isDeploying} disabled={isDeploying} onClick={handleDeploy}>Deploy</Button>
            </div>

            <RealEstateItem
                realEstateObjectName={CLASS_NAME}
                mapper={FIELD_MAPPER}
                extraCells={[
                    {
                        Header: "Add Stage",
                        accessor: "MakeArabic_c",
                        Cell: (_, project) => {
                            return (
                                <RealEstateItem
                                    mapper={STAGE_MAPPER}
                                    formFields={stageFormFields}
                                    realEstateObjectName={STAGE_CLASS_NAME}
                                    transform={transformStageFormValues}
                                    form={{
                                        btn: "Add Stage",
                                        btnId: "add-stage-btn",
                                        disabled: [
                                            "Make_Id",
                                            "Make_c",
                                            "MakeArabic_c",
                                        ],
                                        initialValues: {
                                            Make_c: project.RecordName,
                                            MakeArabic_c: project.MakeArabic_c,
                                            Make_Id: project.objectId,
                                            Year_c: "2022",
                                        },
                                    }}
                                />
                            );
                        },
                    },
                ]}
            />
        </>
    );
}

export default withPrivate(Projects);
