import { withPrivate } from "../../components/PrivateRoute";
import BlogItem from "./BlogItem";
import {
    CLASS_NAME as BLOCK_CLASS_NAME,
    FIELD_MAPPER as BLOCK_FIELD_MAPPER,
    transformBlockFormValues,
    blockFormFields,
} from "./Blocks";

export const CLASS_NAME = "MainImage";
export const INCLUDE = "";

export const FIELD_MAPPER = {
    idengagerfile: "Image Idengager File",
    externalurl: "Image External URL",
    alt: "Image alt",
    objectId: "objectId",
};

export const formFields = (function () {
    const arr = ["idengagerfile", "externalurl", "alt", "objectId"].map(
        (name) => {
            return {
                name,
                label: FIELD_MAPPER[name],
                type: "text",
            };
        }
    );

    return arr;
})();
export function transformMainImageFormValues(values) {
    const data = { ...values };
    return data;
}

function PostMainImages() {
    return (
        <BlogItem
            blogObjectName={CLASS_NAME}
            mapper={FIELD_MAPPER}
            formFields={formFields}
            transform={transformMainImageFormValues}
            extraCells={[
                {
                    Header: "Add Block",
                    accessor: "objectId",
                    Cell: (_, mainImage) => {
                        return (
                            <BlogItem
                                mapper={BLOCK_FIELD_MAPPER}
                                blogObjectName={BLOCK_CLASS_NAME}
                                formFields={blockFormFields}
                                transform={transformBlockFormValues}
                                formClassName="col-2"
                                form={{
                                    btn: "Add Block",
                                    disabled: [
                                        "Make_Id",
                                        "Make_c",
                                        "MakeArabic_c",
                                        "Brand_c",
                                        "BrandArabic_c",
                                        "Brand_Id",
                                    ],
                                    initialValues: {
                                        Make_Id: mainImage.Make_Id,
                                        Make_c: mainImage.Make_c,
                                        MakeArabic_c: mainImage.MakeArabic_c,
                                        Brand_Id: mainImage.objectId,
                                        Brand_c: mainImage.Brand_c,
                                        BrandArabic_c: mainImage.BrandArabic_c,
                                    },
                                }}
                            />
                        );
                    },
                },
            ]}
        />
    );
}

export default withPrivate(PostMainImages);
